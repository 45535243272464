import React from "react";
function Actions ({title, isLoading, refresh, showSearchDiv,showGoDetailDiv, showAddDiv, showCopyDiv, showEditDiv, showDeleteDiv, handleExport, rowData, selectedRows}){
    return(
        <div className="actions">
                        <div className="retrive-data">
                            <div className="tooltip-div">
                                <button className="btn" data-tooltip="refresh" onClick={() => refresh()} disabled={isLoading}><i className="bx bx-refresh" ></i></button>
                            </div>
                            <div className="tooltip-div">
                                <button  className="btn"data-tooltip="search" onClick={() => showSearchDiv()} disabled={isLoading}><i className="bx bx-search" ></i></button>
                            </div>
                            {showGoDetailDiv && 
                                <div className="tooltip-div">
                                    <button className="btn" data-tooltip="go detail" onClick={() => showGoDetailDiv(true)} disabled={selectedRows.length <= 0 ? true: false}><i className="bx bx-arrow-from-bottom"></i></button>
                                </div>
                            }
                        </div>

                        <p>{title}</p>

                        <div className="update-data">
                            {showAddDiv &&
                                <div className="tooltip-div">
                                    <button className="btn" data-tooltip="create" onClick={() => showAddDiv()} disabled={isLoading}><i className="bx bx-plus" ></i></button>
                                </div>
                            }
                            {
                                showCopyDiv && 
                                <div className="tooltip-div">
                                    <button className="btn" data-tooltip="copy" onClick={() =>showCopyDiv()} disabled={selectedRows.length <= 0 ? true: false} style={{cursor: selectedRows.length <= 0 ? 'not-allowed': 'pointer'}}><i className="bx bx-copy" onClick={showAddDiv} ></i></button>
                                </div>
                            }
                            
                            <div className="tooltip-div">
                                <button className="btn" data-tooltip="edit" onClick={() => showEditDiv()} disabled={selectedRows.length <= 0 ? true: false} style={{cursor: selectedRows.length <= 0 ? 'not-allowed': 'pointer'}}><i className="bx bx-edit"></i></button>
                            </div>
                            <div className="tooltip-div">
                                <button className="btn" data-tooltip="delete" onClick={() => showDeleteDiv()} disabled={selectedRows.length <= 0 ? true: false} style={{cursor: selectedRows.length <= 0 ? 'not-allowed': 'pointer'}}><i className="bx bx-x"></i></button>
                            </div>
                            <div className="tooltip-div">
                                <button className="btn"  data-tooltip="export"  onClick={() => handleExport ()} disabled={rowData.length <= 0 ? true: false} style={{cursor: rowData.length <= 0 ? 'not-allowed': 'pointer'}}><i className="bx bx-export"></i></button>
                            </div>
                        </div>
                        
                </div>
    )
}

export default Actions;