import React from "react";
import Select from "react-select"

function AddMod({addFields, submitAdd, moduleTitle, masterErrors, cancelAdd, clearAdd}){
    return(
            <form onSubmit={submitAdd} className="add-module">
                    <p className="add-title">{moduleTitle}</p>
                    <div className="add-body">
                        {masterErrors.error && <p className="form-error-hdr">{masterErrors.error}</p>}
                                <div className="add-row-hdr">
                                    {addFields.map((f) => {
                                        const err = masterErrors[f.id];
                                        return(
                                            <div key={f.id}>
                                                {err && <span className="form-error">{err}</span>}
                                                <div className={`add-row ${err ? 'validation': ''}`}>
                                                   <label htmlFor={f.id}>{f.desc}</label> 
                                                   {f.options &&   
                                                        <Select name={f.name} className="search-select" id={f.id} value={f.value} onChange={f.onChange} options={f.options} placeholder={f.placeHolder}  />
                                                   }  
                                                   
                                                    {!f.options &&  f.type === 'file' &&
                                                    <div className="file-upload-div">
                                                        <span className='file-name'>{ f.fname }</ span>
                                                        <div className="file-upload-input">
                                                            <input type={f.type} className={f.id}  id={f.id} name={f.name}  onChange={f.onChange}   />   
                                                        </div>
                                                    </div>
                                                    }
                                                   
                                                    {!f.options && f.type !== 'file' &&
                                                        <input type={f.type} className={f.id}  id={f.id} name={f.name} value={f.value} onChange={f.onChange} readOnly={f.readOnly} />   
                                                    }
                                                    
                                                    {f.isGenerate ? (<button type="button" onClick={f.onClick} className={`btn-generate${f.readOnly? '-edit': ''}`} disabled={f.readOnly? true:false}>{f.text}</button>) : ''} 
                                                     
                                                  
                                                </div>
                                            </div>
                                            
                                        )
                                        
                                    })}
                                </div>
                            
                            
                        
                        
                    </div>
                    <div className="add-footer">
                        <button type="submit">Save</button>
                        <button type="button" onClick={cancelAdd}>Cancel</button>
                        {clearAdd &&
                            <button type="button" onClick={clearAdd}>Clear</button>
                        }
                    </div>
            </form>
    )
}
export default AddMod;