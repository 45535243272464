import React, { useEffect, useRef, useState } from "react";
import CustomLoadingOverlay from "../Components/Body/loadingOverlay";
import ImageRenderer, { TextRenderer } from "../Components/Body/imageRenderer";
import imageCompression from 'browser-image-compression'
import { getlookupDetail } from "../services/lookupService";
import ShowDetail from "../Utils/showDetail";
import ConfirmationPopup from "../Utils/confirmationPopup";
import Menu from "../Components/Header/menu";
import Search from "../Components/Header/search";
import { Link } from "react-router-dom";
import Actions from "../Components/Header/actions";
import SearchMod from "../Components/Body/searchMod";
import { AgGridReact } from "ag-grid-react";
import AddMod from "../Components/Body/addMod";
import getClubs, { addClub, deleteClub, editClub } from "../services/clubsService";
import { clubsSchema } from "../Validations/clubsValidation";
import ClubDetail from "./clubDetail";

const createdBy = 1;
const updatedBy = 1;
function Clubs(){
    // state variables
    const [rowData, setRowData] = useState([])
    const [masterErrors, setMasterErrors] = useState({});
    const [showSearch, setShowSearch] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [moduleTitle, setModuleTitle] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [loading, setLoading] = useState(false);
    const [nationalityOptions, setNationalityOptions] = useState()
    const [typeOptions, setTypeOptions] = useState()
    const [fname, setFName] = useState('No file chosen')
    const [showPlayer, setShowPlayer] = useState(false)
    const [clickedRow, setClickedRow] = useState()
    const [isloading, setIsLoading] = useState(true)
    const [profile, setProfile] = useState(null)
    const [goDetail, setGoDetail] = useState(false)

    

    //constant variables
    const gridOptions = {loadingOverlayComponent: CustomLoadingOverlay}
    const gridApiRef = useRef(null)
    const loadingTimoutRef = useRef(null);

    //method to format the date displayed in grid
    const dateFormatter = params =>{
        if(!params.value){
            return '';
        }
        const date = new Date(params.value)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
   }

   //method to format the select value
   const selectValueFormatter = (params) =>{
       if (!params.value)
       {
           return ''
       }
       return params.value.label
   }

   //method to format the number value
   const numberValueFormatter = (params) =>{
    if (!params.value)
        {
            return ''
        }
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   }
   
   //method to format the text and number value displayed in grid
   const textValueFormatter = (params) =>{
       if(params.value === null){
           return ''
       }
       return params.value
   }

   //method to show the player detail
   const showDetail = (data) =>{
       setClickedRow(data)
       setShowPlayer(true)
   }
   
    
    const [columnDefs] = useState([
        {headerName:'Id', field: 'id', hide: true },
        {headerName:'Club ID', field: 'clubId', cellRenderer: TextRenderer, cellRendererParams:{showDetail: showDetail} },
        {headerName:'Logo', field: 'logo', cellRenderer: ImageRenderer, cellRendererParams:{showDetail: showDetail}},
        {headerName:'Name', field: 'name', valueFormatter: textValueFormatter},
        {headerName:'Country', field: 'country', valueFormatter: selectValueFormatter},
        {headerName:'City', field: 'city', valueFormatter: selectValueFormatter},
        {headerName:'Stadium', field: 'stadium', valueFormatter: textValueFormatter},
        {headerName:'Stadium Seats', field: 'stadiumSeats', valueFormatter: numberValueFormatter},
        {headerName:'Date of Establishment', field: 'doe',  valueFormatter:dateFormatter},
        {headerName:'Nick Name', field: 'nickName', valueFormatter: textValueFormatter},
        {headerName:'Website', field: 'website', valueFormatter: textValueFormatter},
        {headerName:'Type', field: 'type', valueFormatter: selectValueFormatter},
        {headerName:'Market Value', field: 'marketValue', valueFormatter: numberValueFormatter},
        {headerName: 'Created At', field:'createdAt', valueFormatter: dateFormatter},
        {headerName: 'Created By', field:'createdBy', valueFormatter: textValueFormatter},
        {headerName: 'Updated At', field:'updatedAt', valueFormatter: dateFormatter},
        {headerName: 'Updated By', field:'updatedBy', valueFormatter: textValueFormatter},
    ]);
    const [masterData, setMasterData] = useState({
       id:'',
       clubId: '',
       logo: '',
       name:'',
       nickName:'',
       country: '',
       doe:'',
       city:'',
       stadium: '',
       stadiumSeats:'',
       website:'',
       marketValue:'',
       type:'',
       createdAt:''
    })
    
    const [masterDataSearch, setMasterDataSearch] = useState({
        id:'',
        clubId: '',
        logo: '',
        name:'',
        nickName:'',
        country: '',
        doe:'',
        city:'',
        stadium: '',
        stadiumSeats:'',
        website:'',
        marketValue:'',
        type:'',
        createdAt:''
    })

    const rowClassRules = {
        'selected-row': (params) => selectedRows.includes(params.data.clubId),
        'ag-row-even': (params) => params.node.rowIndex % 2 === 0,
        'ag-row-odd': (params) => params.node.rowIndex % 2 !== 0,
    }
    

    //use-effect method to persist the selection state and reapply it
    useEffect(() =>{
        if(gridApiRef.current.api){
            gridApiRef.current.api.forEachNode(node =>{
                node.setSelected(selectedRows.includes(node.data))
            })
        }
    }
    ,[rowData, selectedRows])
    
 

    //methods to remove time out 
    const removeTimeout = () =>{
        if(loadingTimoutRef.current){
            clearTimeout(loadingTimoutRef.current)
        } 
    }

    //method to be executed on ag-grid ready, resize the ag-grid column as per screen width
    const onGridReady = (params) =>{
        gridApiRef.current = params;
       // params.api.sizeColumnsToFit();
        
        params.api.forEachNode(node =>{
            node.setSelected(selectedRows.includes(node.data))
        })
         
    }

    //defaut ag-grid column attributes
    const defaultColDef = {
        resizable: true,
        sortable: true,
       // flex: 1,
        filter: true,
        width:220
       
    }

    
    //method to handle selected rows of ag-grid change
    const onSelectionChanged = (e) =>{
        const selectedRows = e.api.getSelectedRows();
        setSelectedRows(selectedRows)
        setMasterData(selectedRows[0]) 
        if(selectedRows[0] && selectedRows[0].logo) {
           setFName(selectedRows[0].logo.split('/').pop())
           setProfile(null)
        } else{
           setFName('No file chosen')
        }
                    
    }
   

   // method to handle refresh 
   const refresh = async (e) =>{
    try{
        setLoading(true);
        loadingTimoutRef.current = setTimeout(() => {
            setLoading(false);    
        }, 60000);

       const response = await getClubs();
       
       const updatedResponse = response.map(rs =>{
           const nation = nationalityOptions?.find(opt => opt.label === rs.country)
           const typ = typeOptions?.find(opt => opt.label === rs.type)
           
           return {
               ...rs,
               country: nation,
               type: typ 
           }
       })
       
       
        setRowData(updatedResponse)
        setLoading(false)
        removeTimeout()

    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
   }

   
   // method to close the popup in player detail
   const closeHandler = () =>{
       setShowPlayer(false)
   }


   //method to excute when search button clicked
   const showSearchDiv = () => {
        if(showSearch){
            setShowSearch(false)
        } else{
            setShowSearch(true)
        }
   }

   

   //method to be excuted when add button click
   const showAddDiv = () => {
        clearAdd()
        setModuleTitle('Create New record')
        setIsEdit(false)
        if(showAdd){
            setShowAdd(false)
        } else{
            setShowAdd(true)
        }
    }

    //methods to be excuted when copy button clicks
    const showCopyDiv = () =>{
        setModuleTitle('Copy & Create record')
        setIsEdit(false)
        if(showAdd){
            setShowAdd(false)
        } else{
            setShowAdd(true)
            setMasterData(selectedRows[0]) 
            if(selectedRows[0] && selectedRows[0].logo) {
               setFName(selectedRows[0].logo.split('/').pop())
               setProfile(null)
            } else{
               setFName('No file chosen')
            }
        }
        
    }
    //methos to be execute when edit button clicks
    const showEditDiv = () =>{
        setModuleTitle('Edit a record')
        setIsEdit(true)
        if(showAdd){
            setShowAdd(false)
        } else{
            setShowAdd(true)
            setMasterData(selectedRows[0]) 
            if(selectedRows[0] && selectedRows[0].logo) {
               setFName(selectedRows[0].logo.split('/').pop())
               setProfile(null)
            } else{
               setFName('No file chosen')
            } 
        }
        
    }

    //method to be executed when delete button clicks
    const showDeleteDiv = (e) =>{
        setShowPopup(true);
    }
    

    //method to be executed when cancel button clicks
    const handleCancel = () =>{
        setShowPopup(false)
    }


    //method to be executed when cancel button of search module  clicks
   const cancelSearch = () =>{
        setShowSearch(false)
   }

   //method to be excuted when cancel button of add module clicks
   const cancelAdd = () =>{
        setShowAdd(false)
   }
   //method to be executed when go detail button clicked
   const showGoDetail = (arg) =>{
    setGoDetail(arg);
   }


  

   //handle master search data change
   const handleMasterSeachChange = (e)=>{
       const {name, value} = e.target;
       setMasterDataSearch({ ...masterDataSearch, [name]: value})
   }

   
   //handle master search Select data change
   const handleMasterSeachSelectChange = (selectedOption, actionMeta)=>{
       const {name} = actionMeta;
       setMasterDataSearch({ ...masterDataSearch, [name]: selectedOption})
   }

    //handle master data change
   const handleMasterChange = (e) =>{
        const {name, value} = e.target;
        setMasterData({ ...masterData, [name]: value})
   }
    //handle master data Select change
    const handleMasterSelectChange = (selectedOption, actionMeta) =>{
       const {name } = actionMeta;
       
       setMasterData({ ...masterData, [name]: selectedOption})
  }


   //handle file change
   const handleMasterFilechange =  async (e)=>{
       const image = e.target.files[0]
       const options = {
           maxSizeMB: 0.1,
           maxWidthOrHeight: 300,
           useWebWorker: true
       }
       
       if(image){
           try{
               const compressedFile = await imageCompression(image, options)
               const newImg = new File([compressedFile], image.name, {type: image.type})
               setMasterData({ ...masterData ,logo: newImg})
               setProfile(newImg)
               setFName(image.name)
           } catch(err){
               console.error('Error compressing the image')
           }
           
       } else{
           setFName('No file chosen')
       }
       
      
   }

   // handle seach button actions
   const submitSearch = async () =>{
    try{
        setLoading(true);
        loadingTimoutRef.current = setTimeout(() => {
            setLoading(false);
        }, 60000);

        const response = await getClubs(masterDataSearch);
        if(!response.ok){
           setMasterErrors({...masterErrors, 'error': response.error})
       }

        const updatedResponse = response.map(rs =>{
           const nation = nationalityOptions?.find(opt => opt.label === rs.country)
           const typ = typeOptions?.find(opt => opt.label === rs.type)
           return {
               ...rs,
               country: nation,
               type:typ 
           }
       })

        setRowData(updatedResponse)
        setShowSearch(false)
        setLoading(false);
        removeTimeout(); 

    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
   }
    
   //method to crear the seach module fields
   const clearSearch = () =>{
       setMasterDataSearch({
           clubId: '',
           logo: '',
           name:'',
           nickName:'',
           country: '',
           city: '',
           doe: '',
           stadium:'',
           stadiumSeats:'',
           marketValue:'',
           type:'',
           website:''
       })
   }

   //method to clear the add module fields
   const clearAdd = () =>{
    setMasterData({
        clubId: '',
        logo: '',
        name:'',
        nickName:'',
        country: '',
        city: '',
        doe: '',
        stadium:'',
        stadiumSeats:'',
        marketValue:'',
        type:'',
        website:''
    });
   }

  
   //method to validate the input feilds
   const validateForm = async () =>{
    try{
        await clubsSchema.validate(masterData, {abortEarly: false})
        setMasterErrors({})
        return true
    } catch(err){
        const validationErrors = {};
        err.inner?.forEach(error => {
            validationErrors[error.path] = error.message
        })
        
        setMasterErrors(validationErrors)
        return false;
    }
   }

   //method to handle the delete button actions
   const handleDelete = async () =>{
    try{
            for(const rw of selectedRows){
                const id = rw.id;
                const res =await deleteClub(id)
                if(!res.ok){
                   setMasterErrors({...masterErrors, 'error': res.error})
               }
            }     
            setShowPopup(false)
            refresh()
        } catch(err){
            if(err.response){
                const validationErrors = {};
                validationErrors['error'] = err.response.data.error
                setMasterErrors(validationErrors)
                
            } else if(err.request){
                console.error(err.request)
                console.log('no respone from server')
            } else{
                console.error('error', err.message)
                console.log('unkown error')
            }
        }
   }
  
   //method to handle save button actions
   const submitAdd = async (e) =>{
        e.preventDefault();
       
        
        const isValid = await validateForm()
        if(isValid){
            try{
                if(isEdit){
                   const now = new Date();
                   const updatedAt = now.toISOString();
                   const addData = { ...masterData, updatedBy, updatedAt }
                   const res = await editClub(addData.id, addData, profile)
                    if(!res.ok){
                       setMasterErrors({...masterErrors, 'error': res.error})
                   }
                    setShowAdd(false)
                    refresh()
                } else{
                   const now = new Date();
                   const createdAt = now.toISOString();
                   const updatedAt = now.toISOString();
                   const addData = { ...masterData, createdAt, createdBy, updatedAt, updatedBy}
                   const res = await addClub(addData)
                   if(!res.ok){
                       setMasterErrors({...masterErrors, 'error': res.error})
                   }
                    clearAdd()
                    setShowAdd(false)
                    refresh()
                }
                
            } catch(err){
                if(err.response){
                    const validationErrors = {};
                    validationErrors['error'] = err.response.data.error
                    setMasterErrors(validationErrors)
                    
                } else if(err.request){
                    console.error(err.request)
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    console.log('unkown error')
                }
            }
        } else {
           console.log('validation error: ', )
        }
   }

   
   //method to generate the next id
   const generateMasterID = async (e) =>{
        const response = await getClubs();
        if(response.length > 0){
            const clubId = response[response.length - 1].clubId;
            const nextID = parseInt(clubId.substring(1)) + 1
            setMasterData({ ...masterData, clubId: 'C' + nextID.toString().padStart(7, '0')})
        } else{
            setMasterData({ ...masterData, clubId: 'C0000001'})
        }
       
   }

   //method to handle export button actions
   const handleExport = () =>{
        const params = {
            fileName: 'clubs_export.csv',
            columnSeparator: ','
        }
        gridApiRef.current.api.exportDataAsCsv(params)
   }

   const getSelectOptions = async (masterId) => {
       try{
               const response = await getlookupDetail(masterId);
          
               const formatedOptions = response.map(opt => ({
                   value: opt.detailId,
                   label: opt.value
               }))
               return formatedOptions;
       } catch(err){
           console.log('Error fetching rowData: ', err)
       }
       
       
   };
  
   useEffect(()=>{
      try{
       const footOpt = async()=>{
           const nopt = await getSelectOptions(4);
           const ty = await getSelectOptions(5);
           setNationalityOptions(nopt);
           setTypeOptions(ty)
           setIsLoading(false)
       };
       footOpt()
      } catch(err){
       setIsLoading(true)
      } 
       
   },[])


   // search fields
   const searchFields = [
        {id: 'clubId', desc:'Club ID', name: 'clubId', type:'text', value:masterDataSearch.clubId, onChange:handleMasterSeachChange},
        {id: 'name', desc:'Name', name: 'name', type:'text', value:masterDataSearch.name, onChange:handleMasterSeachChange},
        {id: 'nickName', desc:'Nick Name', name: 'nickName', type:'text', value:masterDataSearch.nickName, onChange:handleMasterSeachChange},
        {id: 'type', desc:'Type', name: 'type', type:'Select', options:typeOptions, placeHolder:'Select type...', value:masterDataSearch.type, onChange:handleMasterSeachSelectChange},
        {id: 'country', desc:'Country', name: 'country', type:'Select', options:nationalityOptions, placeHolder:'Select country...', value:masterDataSearch.country, onChange:handleMasterSeachSelectChange},
        {id: 'city', desc:'City', name: 'city', type:'text', value:masterDataSearch.city, onChange:handleMasterSeachChange},
        {id: 'doe', desc:'Founded Date', name: 'doe', type:'date', value:masterDataSearch.doe, onChange:handleMasterSeachChange},
        {id: 'stadium', desc:'Stadium', name: 'stadium', type:'text', value:masterDataSearch.stadium, onChange:handleMasterSeachChange},
        {id: 'stadiumSeats', desc:'Stadium Seats', name: 'stadiumSeats', type:'number', value:masterDataSearch.stadiumSeats, onChange:handleMasterSeachChange},
        {id: 'marketValue', desc:'Market Value', name: 'marketValue', type:'text', value:masterDataSearch.marketValue, onChange:handleMasterSeachChange},
       
    ];

   

    const addFields = [
        {id: 'clubId', desc:'Club ID', name: 'clubId', type:'text', readOnly:isEdit, isGenerate: true, text:'Generate',onClick:generateMasterID, value:masterData? masterData.clubId : '', onChange:handleMasterChange},
        {id: 'name', desc:'Name', name: 'name', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.name: '', onChange:handleMasterChange},
        {id: 'nickName', desc:'Nick Name', name: 'nickName', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.nickName:'', onChange:handleMasterChange},
        {id: 'type', desc:'Type', name: 'type', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:typeOptions, placeHolder:'Select type...', value:masterData? masterData.type:'', onChange:handleMasterSelectChange},
        {id: 'country', desc:'Country', name: 'country', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:nationalityOptions, placeHolder:'Select country...', value:masterData? masterData.country:'', onChange:handleMasterSelectChange},
        {id: 'city', desc:'City', name: 'city', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.city:'', onChange:handleMasterChange},
       
        {id: 'logo', desc:'Logo', name: 'logo', type:'file', profile:profile, fname, readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.logo:'', onChange:handleMasterFilechange},
        {id: 'doe', desc:'Founded Date', name: 'doe', type:'date', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.doe: '', onChange:handleMasterChange},
        {id: 'stadium', desc:'Stadium', name: 'stadium', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.stadium:'', onChange:handleMasterChange},
        
        {id: 'stadiumSeats', desc:'Stadium Seats', name: 'stadiumSeats', type:'number',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.stadiumSeats:'', onChange:handleMasterChange},
        {id: 'website', desc:'Website', name: 'website', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.website:'', onChange:handleMasterChange},
        {id: 'marketValue', desc:'Market Value', name: 'marketValue', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.marketValue:'', onChange:handleMasterChange},
    ];

    return(
        <div>
            <ShowDetail showPopup={showPlayer} type='club' onCancelHandler={closeHandler} content={clickedRow} />
            <ConfirmationPopup showPopup={showPopup} onDeleteHandler={handleDelete} onCancelHandler={handleCancel} content="Are you sure you want to delete the selected records?" />  
            <Menu />
            <Search />

            {
                 goDetail ? <ClubDetail selectedRow={selectedRows[0]} showGoDetail={showGoDetail} />
                 :
                 <div>
                    <div className="current-page">
                        <p ><Link to="/dashboard" className="link">Dashboard</Link></p>
                        <p>/</p>
                        <p>Clubs</p>
                    </div>
                    <div className="page-content">
                        <Actions title='Adjust Clubs here' isLoading={isloading} refresh={refresh} showSearchDiv={showSearchDiv} showGoDetailDiv={showGoDetail}  showAddDiv={showAddDiv} showCopyDiv={showCopyDiv} showEditDiv={showEditDiv} showDeleteDiv={showDeleteDiv} handleExport={handleExport} rowData={rowData} selectedRows={selectedRows} />
                        
                        <div className="page-body">

                            {
                                showSearch ?
                                <SearchMod searchFields={searchFields} submitSearch={submitSearch} cancelSearch={cancelSearch} clearSearch={clearSearch}/>
                                : ''
                            }
                            
                            <div className="ag-theme-quartz ag-grid-container" >
                                <AgGridReact 
                                    gridOptions={gridOptions}
                                    columnDefs={columnDefs}
                                    rowData={rowData} 
                                    rowSelection="multiple" 
                                    defaultColDef={defaultColDef}
                                    domLayout="autoHeight"
                                    onSelectionChanged={onSelectionChanged}
                                    pagination={true}
                                    paginationPageSize={20}
                                    paginationPageSizeSelector={[20, 40]}
                                    loadingOverlayComponentFramework={CustomLoadingOverlay}
                                    loading={loading}
                                    onGridReady={onGridReady}
                                    ref={gridApiRef}
                                    rowClassRules={rowClassRules}
                                    alwaysShowVerticalScroll={true}
                                    alwaysShowHorizontalScroll={true}
                                />
                        </div>

                            {
                                showAdd &&
                                <AddMod addFields={addFields}  submitAdd={submitAdd} moduleTitle={moduleTitle} masterErrors={masterErrors} cancelAdd={cancelAdd} clearAdd={clearAdd} />
                            }
                        
                        </div>
                        <div className="page-footer">
                        </div>

                    </div>
                </div>
            }
            
               
               
                
        </div>
    );
}
export default Clubs;