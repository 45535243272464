import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomLoadingOverlay from "../Components/Body/loadingOverlay";
import ConfirmationPopup from "../Utils/confirmationPopup";
import Actions from "../Components/Header/actions";
import SearchMod from "../Components/Body/searchMod";
import { AgGridReact } from "ag-grid-react";
import AddMod from "../Components/Body/addMod";
import { addlookupDetail, deletelookupDetail, editlookupDetail, getlookupDetail } from "../services/lookupService";
import { detailSchema } from "../Validations/lookupValidation";


function LookupDetail({selectedRow, showGoDetail}){
    // state variables
    const [rowDataDetail, setRowDataDetail] = useState([])
    const [masterErrorsDetail, setMasterErrorsDetail] = useState({});
    const [showSearchDetail, setShowSearchDetail] = useState(false)
    const [showAddDetail, setShowAddDetail] = useState(false)
    const [selectedRowsDetail, setSelectedRowsDetail] = useState([])
    const [masterIdSearchDetail, setMasterIDSearchDetail] = useState('');
    const [DetailIdSearch, setDetailIDSearch] = useState('');
    const [descriptionSearchDetail, setDescriptionSearchDetail] = useState('');
    const [moduleTitleDetail, setModuleTitleDetail] = useState()
    const [isEditDetail, setIsEditDetail] = useState(false)
    const [showPopupDetail, setShowPopupDetail] = useState(false)
    const [loadingDetail, setLoadingDetail] = useState(false);
   

   
    //constant variables
    const gridOptionsDetail = {loadingOverlayComponent: CustomLoadingOverlay}
    const gridApiRefDetail = useRef(null)
    const loadingTimoutRefDetail = useRef(null);
    
    const dateFormatter = params =>{
        if(!params.value){
            return '';
        }
        const date = new Date(params.value)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
       }
    
    const [columnDefs] = useState([
        {headerName:'ID', field: 'id', hide:true},
        {headerName:'Master ID', field: 'masterId'},
        {headerName:'Detail ID', field: 'detailId'},
        {headerName:'Description', field: 'value'},
        {headerName: 'Created At', field:'createdAt', valueFormatter: dateFormatter}
    ]);
    const [masterDataDetail, setMasterDataDetail] = useState({
        id: '',
        masterId: '',
        detailId:'',
        value: '',
        createdAt:''
    })
 

    //methods to remove time out 
    const removeTimeoutDetail = () =>{
        if(loadingTimoutRefDetail.current){
            clearTimeout(loadingTimoutRefDetail.current)
        } 
    }

    //method to be executed on ag-grid ready, resize the ag-grid column as per screen width
    const onGridReadyDetail = (params) =>{
        gridApiRefDetail.current = params;
        params.api.sizeColumnsToFit();
    }

    //defaut ag-grid column attributes
    const defaultColDef = {
        resizable: true,
        sortable: true,
        flex: 1,
        filter: true
    }

    //method to handle selected rows of ag-grid change
    const onSelectionChangedDetail = (e) =>{
        const selectedRows = e.api.getSelectedRows();
        setSelectedRowsDetail(selectedRows)
        setMasterDataDetail(selectedRows[0])              
    }

   // method to handle refresh 
   const refreshDetail = useCallback(async (e) =>{
    try{
        setLoadingDetail(true);
        loadingTimoutRefDetail.current = setTimeout(() => {
            setLoadingDetail(false);    
        }, 60000);

        const response = await getlookupDetail(selectedRow.masterId);
        setRowDataDetail(response)
        setLoadingDetail(false)
        removeTimeoutDetail()

    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
   }, [selectedRow])

   useEffect(()=>{
    refreshDetail()
   }, [refreshDetail])

   //method to excute when search button clicked
   const showSearchDivDetail = () => {
        if(showSearchDetail){
            setShowSearchDetail(false)
        } else{
            setShowSearchDetail(true)
        }
   }

   //method to be excuted when add button click
   const showAddDivDetail = () => {
        clearAddDetail()
        setModuleTitleDetail('Create New record')
        setIsEditDetail(false)
        if(showAddDetail){
            setShowAddDetail(false)
        } else{
            setShowAddDetail(true)
        }
    }

    //methods to be excuted when copy button clicks
    const showCopyDivDetail = () =>{
        setModuleTitleDetail('Copy & Create record')
        setIsEditDetail(false)
        if(showAddDetail){
            setShowAddDetail(false)
        } else{
            setShowAddDetail(true)
            setMasterDataDetail(selectedRowsDetail[0]) 
        }
        
    }
    //methos to be execute when edit button clicks
    const showEditDivDetail = () =>{
        setModuleTitleDetail('Edit a record')
        setIsEditDetail(true)
        if(showAddDetail){
            setShowAddDetail(false)
        } else{
            setShowAddDetail(true)
            setMasterDataDetail(selectedRowsDetail[0]) 
        }
        
    }

    //method to be executed when delete button clicks
    const showDeleteDivDetail = (e) =>{
        setShowPopupDetail(true);
    }
    

    //method to be executed when cancel button clicks
    const handleCancelDetail = () =>{
        setShowPopupDetail(false)
    }


    //method to be executed when cancel button of search module  clicks
   const cancelSearchDetail = () =>{
        setShowSearchDetail(false)
   }

   //method to be excuted when cancel button of add module clicks
   const cancelAddDetail = () =>{
        setShowAddDetail(false)
   }

   //handle master id of seach module change
   const handleMasterIdSearchChangeDetail = (e) =>{
        setMasterIDSearchDetail(e.target.value)
   }

   //handle detail id of seach module change
   const handleDetailIdSearchChange = (e) =>{
    setDetailIDSearch(e.target.value)
}

  
   //handle value of seach module change
   const handleDescriptionSearchChangeDetail = (e) =>{
        setDescriptionSearchDetail(e.target.value)
    }

    //handle master data change
   const handleMasterChangeDetail = (e) =>{
        const {name, value} = e.target;
        setMasterDataDetail({ ...masterDataDetail, [name]: value})
   }

   // handle seach button actions
   const submitSearchDetail = async () =>{
    try{
        setLoadingDetail(true);
        loadingTimoutRefDetail.current = setTimeout(() => {
            setLoadingDetail(false);
        }, 60000);

        const response = await getlookupDetail(masterIdSearchDetail,DetailIdSearch, descriptionSearchDetail);
        setRowDataDetail(response)
        setShowSearchDetail(false)
        setLoadingDetail(false);
        removeTimeoutDetail(); 

    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
   }
    
   //method to crear the seach module fields
   const clearSearchDetail = () =>{
    setMasterIDSearchDetail('');
    setDetailIDSearch('');
    setDescriptionSearchDetail('');
   }

   //method to clear the add module fields
   const clearAddDetail = () =>{
    setMasterDataDetail({
        masterId: '',
        detailId: '',
        value: '',
        createdAt: ''
    });
   }

   
   //method to validate the input feilds
   const validateFormDetail = async () =>{
    try{
        await detailSchema.validate(masterDataDetail, {abortEarly: false})
        setMasterErrorsDetail({})
        return true
    } catch(err){
        const validationErrors = {};
        err.inner.forEach(error => {
            validationErrors[error.path] = error.message
        })
        setMasterErrorsDetail(validationErrors)
        return false;
    }
   }

   //method to handle the delete button actions
   const handleDeleteDetail = async () =>{
    try{
            for(const rw of selectedRowsDetail){
                const id = rw.id;
                await deletelookupDetail(id)
            }     
            setShowPopupDetail(false)
            refreshDetail()
        } catch(err){
            if(err.response){
                const validationErrors = {};
                validationErrors['error'] = err.response.data.error
                setMasterErrorsDetail(validationErrors)
                
            } else if(err.request){
                console.error(err.request)
                console.log('no respone from server')
            } else{
                console.error('error', err.message)
                console.log('unkown error')
            }
        }
   }
  
   //method to handle save button actions
   const submitAddDetail = async (e) =>{
        e.preventDefault();
        const now = new Date();
        const createdAt = now.toISOString();
        const masterId = selectedRow.masterId;
        const addData = { ...masterDataDetail, masterId, createdAt}
        const isValid = await validateFormDetail()
        if(isValid){
           
            try{
                if(isEditDetail){
                    await editlookupDetail(addData.id, addData)
                    setShowAddDetail(false)
                    refreshDetail()
                } else{
                    await addlookupDetail(addData)
                    clearAddDetail()
                    setShowAddDetail(false)
                    refreshDetail()
                }
                
            } catch(err){
                if(err.response){
                    const validationErrors = {};
                    validationErrors['error'] = err.response.data.error
                    setMasterErrorsDetail(validationErrors)
                    
                } else if(err.request){
                    console.error(err.request)
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    console.log('unkown error')
                }
            }
        } 
   }

   
   //method to generate the next id
   const generateDetailID = async (e) =>{
        const response = await getlookupDetail(selectedRow.masterId);
        if(response.length > 0){
            const nextID = response[response.length - 1].detailId + 1;
            setMasterDataDetail({ ...masterDataDetail, detailId: nextID})
        } else{
            setMasterDataDetail({ ...masterDataDetail, detailId: 1})
        }
       
       
   }

   //method to handle export button actions
   const handleExportDetail = () =>{
        const params = {
            fileName: 'lookuDetail_export.csv',
            columnSeparator: ','
        }
        gridApiRefDetail.current.api.exportDataAsCsv(params)
   }

   const handleBackToMaster = ()=>{
        showGoDetail(false)
   }

   // search fields
   const searchFieldsDetail = [
        {id: 'masterId', desc:'Master ID', name: 'masterId', type:'number', value:masterIdSearchDetail, onChange:handleMasterIdSearchChangeDetail},
        {id: 'detailId', desc:'Detail ID', name: 'detailId', type:'number', value:DetailIdSearch, onChange:handleDetailIdSearchChange},
        {id: 'value', desc:'Description', name: 'value', type:'text', value:descriptionSearchDetail, onChange:handleDescriptionSearchChangeDetail}
    ];

    
    const addFieldsDetail = [
        {id: 'detailId', desc:'Detail ID', name: 'detailId', type:'number', readOnly:isEditDetail, isGenerate: true,text:'Generate', onClick:generateDetailID, value:masterDataDetail ? masterDataDetail.detailId: '', onChange:handleMasterChangeDetail},
        {id: 'value', desc:'Description', name: 'value', type:'text', readOnly:false, isGenerate: false,onclick:'', value:masterDataDetail ? masterDataDetail.value : '', onChange:handleMasterChangeDetail}
    ];

  

    return(
        <div>
            <ConfirmationPopup showPopup={showPopupDetail} onDeleteHandler={handleDeleteDetail} onCancelHandler={handleCancelDetail} content="Are you sure you want to delete the selected records?" />  
                <div className="current-page">
                            <p onClick={handleBackToMaster} className="link"><i className="bx bx-arrow-back"> Lookup Master</i></p>
                            <p>/</p>
                            <p>Lookup Detail</p>
                        </div>
                <div className="page-content">
                    <Actions title='Adjust lookup Detail here' refresh={refreshDetail}  showSearchDiv={showSearchDivDetail} isGoDetail={false} showAddDiv={showAddDivDetail} showCopyDiv={showCopyDivDetail} showEditDiv={showEditDivDetail} showDeleteDiv={showDeleteDivDetail} handleExport={handleExportDetail} rowData={rowDataDetail} selectedRows={selectedRowsDetail} />
                    
                    <div className="page-body">

                        {
                            showSearchDetail ?
                            <SearchMod searchFields={searchFieldsDetail} submitSearch={submitSearchDetail} cancelSearch={cancelSearchDetail} clearSearch={clearSearchDetail}/>
                            : ''
                        }
                        
                        <div className="ag-theme-quartz" style={{ height: '100%', width: '100%', margin:'6px 0'}}>
                            <AgGridReact 
                                gridOptions={gridOptionsDetail}
                                rowData={rowDataDetail} 
                                columnDefs={columnDefs} 
                                rowSelection="multiple" 
                                defaultColDef={defaultColDef}
                                domLayout="autoHeight"
                                onSelectionChanged={onSelectionChangedDetail}
                                pagination={true}
                                paginationPageSize={20}
                                paginationPageSizeSelector={[20, 40]}
                                loadingOverlayComponentFramework={CustomLoadingOverlay}
                                loading={loadingDetail}
                                onGridReady={onGridReadyDetail}
                                ref={gridApiRefDetail}
                            />
                    </div>
                    {
                            showAddDetail &&
                            <AddMod addFields={addFieldsDetail} submitAdd={submitAddDetail} moduleTitle={moduleTitleDetail} masterErrors={masterErrorsDetail} cancelAdd={cancelAddDetail} clearAdd={clearAddDetail} />
                        }
                    
                    </div>
                    <div className="page-footer">
                    </div>

            </div>
            
        </div>
    )
}

export default LookupDetail;