import React, { forwardRef, useImperativeHandle, useState } from "react";
import ImageRenderer, { TextRenderer } from "../Components/Body/imageRenderer";
import getClubs from "../services/clubsService";
import getPlayers from "../services/playersService";
import getMatchs from "../services/matchService";
import ShowDetail from "./showDetail";
import Select from "react-select"
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "../Components/Body/loadingOverlay";


const MatchFilter = forwardRef((props, ref)=>{

    const {showFilterPopup, onHideFilterPopup, filterAttributes, setAddAttributes, searchFields, tableName, matchOptions, setIsEdit,handlePlayersOption} = props
    
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(false);


    const gridOptions = {loadingOverlayComponent: CustomLoadingOverlay}
  
    
    //method to format the text and number value displayed in grid
   const textValueFormatter = (params) =>{
    if(params.value === null){
        return ''
    }
    return params.value
    }

    //method to format the date displayed in grid
    const dateFormatter = params =>{
        if(!params.value){
            return '';
        }
        const date = new Date(params.value)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    }

    let cols = ''
    if(tableName === 'clubs'){
        cols = [
            {headerName:'Id', field: 'id', hide: true },
            {headerName:'club Id', field: 'clubId', width:120, cellRenderer: TextRenderer, cellRendererParams:{showDetail: ShowDetail}},
            {headerName:'logo', field: 'logo', width:120, cellRenderer: ImageRenderer, cellRendererParams:{showDetail: ShowDetail}},
            {headerName:'Name', field: 'name', width: 320, valueFormatter: textValueFormatter},
            {headerName:'Country', field: 'country', width:120, valueFormatter: textValueFormatter},
    
        ] 
    } else if(tableName === 'matchs'){
        cols = [
            {headerName:'Id', field: 'id', hide: true, valueFormatter: textValueFormatter },
            {headerName:'league Name', field: 'leagueName', width:200, valueFormatter: textValueFormatter},
            {headerName:'home', field: 'home', width: 200, valueFormatter: textValueFormatter},
            {headerName:'away', field: 'away', width:200, valueFormatter: textValueFormatter},
            {headerName:'match date', field: 'dop', width:200, valueFormatter: dateFormatter},
        ] 
    }else{
        cols =[
            {headerName:'Id', field: 'id', hide: true },
            {headerName:'staff Id', field: 'playerId', width:120, cellRenderer: TextRenderer, cellRendererParams:{showDetail: ShowDetail}},
            {headerName:'profile', field: 'playerPhoto', width:120, cellRenderer: ImageRenderer, cellRendererParams:{showDetail: ShowDetail}},
            {headerName:'Full Name', field: 'fullName', width: 320, valueFormatter: textValueFormatter},
            {headerName:'Country', field: 'citizenShip', width:120, valueFormatter: textValueFormatter},
    
        ]
    }

    const [colDef] = useState(cols)
   

     //method to handle selected rows of ag-grid change
     const onSelectionChanged = (e) =>{
        const rows = e.api.getSelectedRows();
        setAddAttributes(rows[0])
        handlePlayersOption(rows[0])
        setIsEdit(true)
    }
    //method to handle selected rows of ag-grid change
    const onRowClicked = (e) =>{
        const row = e.data;
        setAddAttributes(row)
        handlePlayersOption(row)
        setIsEdit(true)
       
    }
  

    const rowClassRules = {
        'ag-row-even': (params) => params.node.rowIndex % 2 === 0,
        'ag-row-odd': (params) => params.node.rowIndex % 2 !== 0,
    }
     //defaut ag-grid column attributes
     const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
       
    }
   
   //method to get staff selection options
   useImperativeHandle(ref, ()=>({
     handleFilter,
     setFilteredData,
   }))
   const handleFilter = async (e)=>{
    try{
        let response
        setLoading(true);
        if(tableName === 'clubs'){
            const data = ({
                name: filterAttributes.fullName,
            })
            response = await getClubs(data);
        } if(tableName === 'matchs'){
            const data = ({
                leagueName: filterAttributes.leagueName,
                home: filterAttributes.home,
                away: filterAttributes.away,
                dop: filterAttributes.dop
            })
           
            const res = await getMatchs(data);

        
            const updatedResponse = res.map(rs =>{
                const fxId = matchOptions?.find(opt => opt.value === parseInt(rs.fixtureId))
                return {
                    ...rs,
                    fixtureId: fxId, 
                }
            })
            response  = updatedResponse
        } else{
            const data = ({
                fullName: filterAttributes.fullName,
                status: 'Active'
            })
            response = await getPlayers(data);
        }
            
        setFilteredData(response)
        setLoading(false);
        
        } catch(err){
            console.log('Error fetching rowData: ', err)
        }
    }


    

    return(
        <div className={`popup ${showFilterPopup ? 'show': ''}`}>
             <div className="popup-content">
                <div className="popup-hdr">
                    <p>Filter</p>
                    <button className="close-button" onClick={onHideFilterPopup}><i className='bx bx-x'></i></button>
                </div>
               
                <div className="filter-body">
                    <div className="match-filter-hdr">
                        { searchFields.map((f) =>(
                            
                                f.options ?   
                                    <Select name={f.name} key={f.id} className="filter-select" id={f.id} value={f.value} onChange={f.onChange} options={f.options} placeholder={f.placeHolder} />  
                                    :
                                    <input key={f.id} className="filter-input" type={f.type} id={f.id} name={f.name} value={f?.value ? f.value: ''} onChange={f.onChange} placeholder={f.placeHolder} />
                               
                            ))
                        }
                        <button onClick={handleFilter} className="match-filter-btn"><i className="bx bx-search"></i></button>
                    </div>

                    <div className="ag-theme-quartz ag-grid-cont">
                        <AgGridReact
                            columnDefs={colDef}
                            rowData={filteredData} 
                            rowSelection="single" 
                            onSelectionChanged={onSelectionChanged}
                            defaultColDef={defaultColDef}
                            rowClassRules={rowClassRules}
                            gridOptions={gridOptions}
                            loadingOverlayComponentFramework={CustomLoadingOverlay}
                            loading={loading}
                            onRowClicked={onRowClicked}
                            onRowSelected={onRowClicked}
                        />

                        
                    </div>
                    
                </div>
                <div className="popup-buttons">
                    <button onClick={onHideFilterPopup} className="popup-no">Ok</button>
                </div>
             </div>
           
        </div>
    )


}) 
    

export default MatchFilter;