import React from "react";
import Select from "react-select"

function SearchMod({searchFields, submitSearch, cancelSearch, clearSearch}){
    return(
        <div className="search-module">
                <p className="search-title">Search Here</p>
                
                <div className="search-body">
                    {
                        searchFields.map((f) =>(
                           
                             <div className="search-row" key={f.id}>
                                <label htmlFor={f.id}>{f.desc}</label>
                                {f.options ?   
                                    <Select name={f.name} className="search-select" id={f.id} value={f.value} onChange={f.onChange} options={f.options} placeholder={f.placeHolder} />  
                                    :
                                    <input type={f.type} id={f.id} name={f.name} value={f.value} onChange={f.onChange} />
                                }

                            </div>
                        ))
                    }
                       
                </div>
                <div className="search-footer">
                    <button onClick={submitSearch}>Search</button>
                    <button onClick={cancelSearch}>Cancel</button>
                    <button onClick={clearSearch}>Clear</button>
                </div>
            
                            
        </div>
    )
}
export default SearchMod;