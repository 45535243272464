import "./header.css"
import React, { useState }  from "react";
import { Link } from "react-router-dom"

function Menu(){
    const [smallMob2, setSmallMob2] = useState(false)
    const [subMenus, setSubMenus] = useState({
        english: false,
        spain: false,
        italy: false,
        germany: false,
        france: false,
        europe: false,
        international: false
    })
   
    const toggleSubMenu = (menu) =>{
        setSubMenus((prev)=>({
            ...prev,
            [menu]: !prev[menu]
            
        }))
    }

    const handleSmallMob2 = () =>{
        setSmallMob2(!smallMob2)
    }

    
    return(
        <div className="header-menu">
                    <nav className='my-nav'>
                        <div  className="my-nav-home">
                            <Link to="/" className='my-nav-link'>Home</Link>
                        </div>
                       
                       <div className="my-nav-div">
                            <ul className={`my-nav-menu ${smallMob2 ? 'active': ''}`}>
                                    <li className='my-nav-header'>
                                        <div className="my-nav-title">
                                            <Link className='my-nav-link' onClick={()=>toggleSubMenu('english')}>England</Link> 
                                            <i className={`bx ${smallMob2 && !subMenus.english ? 'bx-caret-right nav-hor-space': smallMob2 && subMenus.english ? 'bx-caret-down nav-no-space': !smallMob2 && !subMenus.english? 'bx-caret-down nav-no-space': 'bx-caret-up nav-no-space'}`} onClick={()=>toggleSubMenu('english')}></i>
                                        </div>
                                        <ul className={`my-nav-sub-menu ${subMenus.english ? 'active': ''}`}>
                                            <li className='vertical-space'></li>
                                            <li ><Link to="#" className='my-nav-link'>Premier League</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>ChampionsShip</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>Carabao Cup</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>FA Cup</Link></li>
                                        </ul>
                                    </li>
                                    <li className='my-nav-header'>
                                        <div className="my-nav-title">
                                            <Link to="#" className='my-nav-link' onClick={()=>toggleSubMenu('spain')}>Spain</Link>
                                            <i className={`bx ${smallMob2 && !subMenus.spain ? 'bx-caret-right nav-hor-space': smallMob2 && subMenus.spain ? 'bx-caret-down nav-no-space': !smallMob2 && !subMenus.spain? 'bx-caret-down nav-no-space': 'bx-caret-up nav-no-space'}`} onClick={()=>toggleSubMenu('spain')}></i>
                                        </div>
                                        <ul className={`my-nav-sub-menu ${subMenus.spain ? 'active': ''}`}>
                                            <li className='vertical-space'></li>
                                            <li ><Link to="#" className='my-nav-link'>La Liga</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>Copa Del Ray</Link></li>
                                        </ul>
                                    
                                    </li>
                                    <li className='my-nav-header'>
                                        <div className="my-nav-title">
                                            <Link to="#" className='my-nav-link' onClick={()=>toggleSubMenu('italy')}>Italy</Link>
                                            <i className={`bx ${smallMob2 && !subMenus.italy ? 'bx-caret-right nav-hor-space': smallMob2 && subMenus.italy ? 'bx-caret-down nav-no-space': !smallMob2 && !subMenus.italy? 'bx-caret-down nav-no-space': 'bx-caret-up nav-no-space'}`} onClick={()=>toggleSubMenu('italy')}></i>

                                        </div>
                                        <ul className={`my-nav-sub-menu ${subMenus.italy ? 'active': ''}`}>
                                            <li className='vertical-space'></li>
                                            <li ><Link to="#" className='my-nav-link'>Serie A</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>Copa Italia</Link></li>
                                        </ul>
                                    
                                    </li>
                                    <li className='my-nav-header'>
                                        <div className="my-nav-title">
                                            <Link to="#" className='my-nav-link' onClick={()=>toggleSubMenu('germany')}>Germany</Link>
                                            <i className={`bx ${smallMob2 && !subMenus.germany ? 'bx-caret-right nav-hor-space': smallMob2 && subMenus.germany ? 'bx-caret-down nav-no-space': !smallMob2 && !subMenus.germany? 'bx-caret-down nav-no-space': 'bx-caret-up nav-no-space'}`} onClick={()=>toggleSubMenu('germany')}></i>

                                        </div>
                                        <ul className={`my-nav-sub-menu ${subMenus.germany ? 'active': ''}`}>
                                            <li className='vertical-space'></li>
                                            <li ><Link to="#" className='my-nav-link'>BundesLiga</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>DFB Pokal</Link></li>
                                        </ul>
                                    
                                    </li>
                                    <li className='my-nav-header'>
                                        <div className="my-nav-title">
                                            <Link to="#" className='my-nav-link' onClick={()=>toggleSubMenu('france')}>France</Link>
                                            <i className={`bx ${smallMob2 && !subMenus.france ? 'bx-caret-right nav-hor-space': smallMob2 && subMenus.france ? 'bx-caret-down nav-no-space': !smallMob2 && !subMenus.france? 'bx-caret-down nav-no-space': 'bx-caret-up nav-no-space'}`} onClick={()=>toggleSubMenu('france')}></i>

                                        </div>
                                        <ul className={`my-nav-sub-menu ${subMenus.france ? 'active': ''}`}>
                                            <li className='vertical-space'></li>
                                            <li ><Link to="#" className='my-nav-link'>Ligue 1</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>Coupe De France</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>Coupe De La Ligue</Link></li>
                                        </ul>
                                    
                                    </li>
                                    <li className='my-nav-header'>
                                        <div className="my-nav-title">
                                            <Link to="#" className='my-nav-link' onClick={()=>toggleSubMenu('europe')}>Europe</Link>
                                            <i className={`bx ${smallMob2 && !subMenus.europe ? 'bx-caret-right nav-hor-space': smallMob2 && subMenus.europe ? 'bx-caret-down nav-no-space': !smallMob2 && !subMenus.europe? 'bx-caret-down nav-no-space': 'bx-caret-up nav-no-space'}`} onClick={()=>toggleSubMenu('europe')}></i>

                                        </div>
                                       
                                        <ul className={`my-nav-sub-menu ${subMenus.europe ? 'active': ''}`}>
                                            <li className='vertical-space'></li>
                                            <li ><Link to="#" className='my-nav-link'>Champions League</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>Nations League</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>Europa League</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>Super Cup</Link></li>
                                        </ul>
                                    
                                    </li>
                                    <li className='my-nav-header'>
                                        <div className="my-nav-title">
                                            <Link to="#" className='my-nav-link' onClick={()=>toggleSubMenu('international')}>International</Link>
                                            <i className={`bx ${smallMob2 && !subMenus.international ? 'bx-caret-right nav-hor-space': smallMob2 && subMenus.international ? 'bx-caret-down nav-no-space': !smallMob2 && !subMenus.international? 'bx-caret-down nav-no-space': 'bx-caret-up nav-no-space'}`} onClick={()=>toggleSubMenu('international')}></i>
                                        </div>
                                        <ul className={`my-nav-sub-menu ${subMenus.international ? 'active': ''}`}>
                                            <li className='vertical-space'></li>
                                            <li ><Link to="#" className='my-nav-link'>Friendly Match</Link></li>
                                            <li className='horizontal-divider'></li>
                                            <li ><Link to="#" className='my-nav-link'>World Cup</Link></li>
                                            <li className='horizontal-divider'></li>
                                        </ul>
                                    
                                    </li>

                                
                            </ul>
                       </div>
                        
                        <div className="hamburger2" onClick={handleSmallMob2}>
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                        </div>
                       
                    </nav>
            </div>
    )
}
export default Menu;