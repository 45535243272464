import './header.css'
import cup from '../../Assets/images/cup.png'
import { useState } from 'react'
import getMatchs from '../../services/matchService'



function Search({matchs, setMatchs}){
    const[search, setSearch] = useState('')


    const searchFootabll =  async() =>{
        const criteria = {homeSearch: 'homeSearch',home: search.toLocaleLowerCase(), away: search.toLocaleLowerCase(), leagueName: search.toLocaleLowerCase()}
        const hghl = await getMatchs(criteria)
        setMatchs(hghl)
    }
    
    const handleSearchKeyDown = (e)=>{
        if(e.key === 'Enter'){
            searchFootabll(e)
        }
    }
    const handleSearchChange = (e)=>{
        setSearch(e.target.value)
    }
    return(
        <div>
             <div className='triangle'>
                <div class="header-shape-divider">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" class="shape-fill"></path>
                    </svg>
                </div> 
            </div>
            
            
            <div className='cup-search'>
                <div className="header-search">
                        <div >
                            <input type='text' name='search' placeholder='your search value here' className='inputSearch' value={search} onChange={handleSearchChange} onKeyDown={handleSearchKeyDown}/>
                            <button name='btnSearch' onClick={searchFootabll} className='btnSearch'><i className='bx bx-search-alt-2'></i></button>
                        </div>
                    
                </div>
                <div className='cup'>
                    <img src={cup} alt='cup' className='cup-img'/>
                </div>
            </div>
        </div>
       
    )
}

export default Search;