import React from "react";

function ConfirmationPopup ({showPopup, onDeleteHandler, onCancelHandler, content}) {
    return(
        <div className={`popup ${showPopup ? 'show': ''}`}>
            <div className="popup-content">
                <div className="popup-hdr">
                    <p>Delete</p>
                    <button className="close-button" onClick={onCancelHandler}><i className='bx bx-x'></i></button>
                </div>
                <div className="popup-body">
                    <p>{content}</p>
                </div>
                <div className="popup-buttons">
                    <button onClick={onDeleteHandler} className="popup-yes">Delete</button>
                    <button onClick={onCancelHandler} className="popup-no">Cancel</button>
                </div>
            </div>

        </div>
    )
}
export default ConfirmationPopup;