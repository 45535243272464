import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { axiosInstance } from '../Utils/axiosInstance';
import { setApiKey } from './authSlice';

export const useAuthToken = () => {
    
    const userState = useSelector((state) => state.auth)
    //return userState.token;
    return userState
};



export const fetchCsrfTokenFromCookie = () => {
    return Cookies.get('csrftoken');
};

// export const getApiKey = async () =>{
//     try {
//         const response = await axiosInstance.get('apiKey');
//         const api_key = response.data.Api_key;

//         const responseT = await axiosInstance.get('tokenPublic');
//         const public_token = responseT.data.public_token;

//         return {api_key, public_token}
//     } catch (error) {
//         console.error('Error fetching public keys:', error);
//         return {api_key: null, public_token: null}
//     }
// }



export const getApiKey = async (dispatch) => {
    try {
        const response = await axiosInstance.get('apiKey');
       
        const apiKey = response.data.Api_key;

        //const responseT = await axiosInstance.get('tokenPublic');
        
        //const publicToken = responseT.data.public_token;

        dispatch(setApiKey({ apiKey}));
        


    } catch (error) {
        console.error('Error fetching public keys:', error);
        return { api_key: null, public_token: null };
    } 
}


export const getPublicToken = async () =>{
    try {
        const response = await axiosInstance.get('tokenPublic');
        const public_token = response.data.public_token;
        return public_token
    } catch (error) {
        console.error('Error fetching public token:', error);
    }
}
