import * as yup from 'yup'

export const clubsDetailSchema = yup.object().shape({
    staffName: yup.string().required('* Staff Name required'),
    shirtNbr: yup.number().required('* T-shirt Number required'),
    squadType: yup.object().required('* League Squad required'),
    leagueType: yup.object().required('* League Type required'),
    leagueLevel: yup.object().required('* League Level required'),
    fromDate: yup.date().required('* From date required'),
    toDate: yup.date().required('* To date required'),
    clubStatus: yup.object().required('* status required'),
    isCaptain: yup.object().required('* is captain required'),

});

