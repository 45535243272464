import React from "react";

//const backEndUrl = "http://localhost:8000/media/"

const backEndUrl = "https://footballback.zeaye.com/media/"

export default function ImageRenderer(props){
    const {value, showDetail, data} = props;
    return(
        <div className="grid-div-img">
            {value ?
                <img src={`${backEndUrl}${value}`} alt={`${props.data.firstName} ${props.data.lastName}`} className="grid-img" onClick={(e) => showDetail(data)} />
                :
                <i className="bx bx-user-circle grid-icon" onClick={(e) => showDetail(data)} ></i>
            }
        </div> 
    )
}


export function TextRenderer(props){
    const {value, showDetail, data} = props;
    return(
        <div>
                <div className="gird-div-txt">
                    <p className="grid-txt" onClick={(e) => showDetail(data)}>{value}</p>
                </div>
        </div>
       
    )
}
