import './footer.css'
import {Link} from 'react-router-dom'
import myfootball from '../../Assets/images/myfootball.png'
function Footer(){
    return (
        <div className="my-container">
            <div className="footer">
                <ul className="row">
                    <li className="col-1-2">
                        <div>
                            <Link to='/'><img src={myfootball} alt="my football" className="footer-logo"/></Link>
                            <address className='footer-address'>
                                Latest Football Highlights & Analysis <br />
                            </address>
                        </div>

                    </li>
                    <li className="col-1-2">
                        <div className="footer-social">
                            <Link to="/" className='link'><i className='bx bxl-twitter'></i></Link>
                            <Link to="/" className='link'><i className='bx bxl-facebook'></i></Link>
                            <Link to="/" className='link'><i className='bx bxl-instagram'></i></Link>
                        </div>
                    </li>   
                </ul>
                <div className='horizontal-divider-withoutmargin'>
                </div>
                <div className="footer-copyright">
                    <p> ©2024 Myfootball, All rights reserved</p>
                    <ul className="footer-nav">
                        <li ><Link to='' className="footer-nav-link">Terms of Use</Link></li>
                        <li ><Link to='' className="footer-nav-link">Privacy Policy</Link></li>
                        <li ><Link to='' className="footer-nav-link">Cookie Policy</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default Footer