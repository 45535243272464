import { useEffect, useRef, useState } from "react";
import CustomLoadingOverlay from "../Components/Body/loadingOverlay";
import { TextRenderer } from "../Components/Body/imageRenderer";
import { getlookupDetail } from "../services/lookupService";
import ShowDetail from "../Utils/showDetail";
import Filter from "../Utils/filter";
import ConfirmationPopup from "../Utils/confirmationPopup";
import Actions from "../Components/Header/actions";
import SearchMod from "../Components/Body/searchMod";
import { AgGridReact } from "ag-grid-react";
import AddMod from "../Components/Body/addMod";
import getleaguesDetail, { addleagueDetail, deleteleagueDetail, editleagueDetail } from "../services/leaguesDetailService";
import { leaguesDetailSchema } from "../Validations/leaguesDetailValidation";

const createdBy = 1;
const updatedBy = 1;

function LeaguesDetail({selectedRow, showGoDetail}){
    // state variables
    const [rowDataDetail, setRowDataDetail] = useState([])
    const [masterErrorsDetail, setMasterErrorsDetail] = useState({});
    const [showSearchDetail, setShowSearchDetail] = useState(false)
    const [showAddDetail, setShowAddDetail] = useState(false)
    const [selectedRowsDetail, setSelectedRowsDetail] = useState([])
    const [moduleTitleDetail, setModuleTitleDetail] = useState()
    const [isEditDetail, setIsEditDetail] = useState(false)
    const [showPopupDetail, setShowPopupDetail] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [squadTypeOptions, setSquadTypeOptions] = useState()
    const [leagueTypeOptions, setLeagueTypeOptions] = useState()
    const [LeagueLevelOptions, setLeagueLevelOptions] = useState()
    const [statusOptions, setStatusOptions] = useState()
    const [isloading, setIsLoading] = useState(true)
    const [clubNameF, setClubNameF] = useState()
    const [clubIdF, setClubIdF] = useState()
    const [showPlayer, setShowPlayer] = useState(false)
    const [clickedRow, setClickedRow] = useState()

    const filterRef = useRef()
   

   
    //constant variables
    const gridOptionsDetail = {loadingOverlayComponent: CustomLoadingOverlay}
    const gridApiRefDetail = useRef(null)
    const loadingTimoutRefDetail = useRef(null);
    
    //method to format the text and number value displayed in grid
   const textValueFormatter = (params) =>{
       if(params.value === null){
           return ''
       }
       return params.value
   }
    //method to show the player detail
    const showDetail = (data) =>{
        setClickedRow(data)
        setShowPlayer(true)
    }

      //method to format the select value
   const selectValueFormatter = (params) =>{
    if (!params.value)
    {
        return ''
    }
    return params.value.label
}

 
    

    const dateFormatter = params =>{
        if(!params.value){
            return '';
        }
        const date = new Date(params.value)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
       }
    
    const [columnDefs] = useState([
        {headerName:'League Detail ID', field: 'id'},
        {headerName:'League ID', field: 'leagueIdFull'},
        {headerName:'League Name', field: 'leagueName'},
        {headerName:'Club pk', field: 'clubId', hide: true},
        {headerName:'Club ID', field: 'clubIdFull', cellRenderer: TextRenderer, cellRendererParams:{showDetail: showDetail}},
        {headerName:'Club Name', field: 'clubName'},
        {headerName:'Club Type', field: 'clubType'},
        {headerName:'From', field: 'fromDate', valueFormatter: dateFormatter},
        {headerName:'to', field: 'toDate', valueFormatter: dateFormatter},
        {headerName:'League Squad', field: 'squadType', valueFormatter: selectValueFormatter},
        {headerName:'League Type', field: 'leagueType', valueFormatter: selectValueFormatter},
        {headerName:'League Level', field: 'leagueLevel', valueFormatter: selectValueFormatter},
        {headerName:'Status', field: 'status', valueFormatter: selectValueFormatter},
        {headerName: 'Created At', field:'createdAt', valueFormatter: dateFormatter},
        {headerName: 'Created By', field:'createdBy', valueFormatter: textValueFormatter},
        {headerName: 'Updated At', field:'updatedAt', valueFormatter: dateFormatter},
        {headerName: 'Updated By', field:'updatedBy', valueFormatter: textValueFormatter},
    ]);
    const [masterDataDetail, setMasterDataDetail] = useState({
        id: '',
        leagueId: '',
        leagueName: '',
        clubId:'',
        clubIdFull:'',
        clubName: '',
        fromDate: '',
        toDate:'',
        squadType: '',
        leagueType:'',
        leagueLevel:'',
        status: '',
       
    })

    const [masterDataDetailSearch, setMasterDataDetailSearch] = useState({
        id: '',
        leagueId: '',
        leagueName: '',
        clubId:'',
        clubIdFull:'',
        clubName: '',
        fromDate: '',
        toDate:'',
        squadType: '',
        leagueType:'',
        leagueLevel:'',
        status: '',
    })

    const rowClassRules = {
        'selected-row': (params) => selectedRowsDetail.includes(params.data.id),
        'ag-row-even': (params) => params.node.rowIndex % 2 === 0,
        'ag-row-odd': (params) => params.node.rowIndex % 2 !== 0,
    }
 

    //methods to remove time out 
    const removeTimeoutDetail = () =>{
        if(loadingTimoutRefDetail.current){
            clearTimeout(loadingTimoutRefDetail.current)
        } 
    }

    //method to be executed on ag-grid ready, resize the ag-grid column as per screen width
    const onGridReadyDetail = (params) =>{
        gridApiRefDetail.current = params;
        params.api.sizeColumnsToFit();
    }

    //defaut ag-grid column attributes
    const defaultColDef = {
        resizable: true,
         sortable: true,
        // flex: 1,
         filter: true,
         width:220
    }

    //method to det the lookup detail options
    const getSelectOptions = async (masterId) => {
        try{
                const response = await getlookupDetail(masterId);
           
                const formatedOptions = response.map(opt => ({
                    value: opt.detailId,
                    label: opt.value
                }))
                return formatedOptions;
        } catch(err){
            console.log('Error fetching rowData: ', err)
        }
    };

   
    //method to handle selected rows of ag-grid change
    const onSelectionChangedDetail = (e) =>{
        const selectedRows = e.api.getSelectedRows();
        setSelectedRowsDetail(selectedRows)
        setMasterDataDetail(selectedRows[0])  
        setClubNameF(selectedRows[0]?.clubName) 
        setClubIdF(selectedRows[0]?.clubId)
       
    }

 
   // method to handle refresh 
   const refreshDetail = async (e) =>{
    try{
        setLoadingDetail(true);
        loadingTimoutRefDetail.current = setTimeout(() => {
            setLoadingDetail(false);    
        }, 60000);

        const response = await getleaguesDetail(selectedRow.id);
        if(!response.ok){
            setMasterErrorsDetail({...setMasterErrorsDetail, 'error': response.error})
        }

          
           
            const updatedResponse = response.map(rs =>{
            const lgType = leagueTypeOptions?.find(opt => opt.label === rs.leagueType)
            const lgLevel = LeagueLevelOptions?.find(opt => opt.label === rs.leagueLevel)
            const squad = squadTypeOptions?.find(opt => opt.label === rs.squadType)
            const sts = statusOptions?.find(opt => opt.label === rs.status)
        
            return {
                ...rs,
                leagueType: lgType,
                leagueLevel: lgLevel,
                squadType: squad,
                status: sts,
            }
        }) 

       
        setRowDataDetail(updatedResponse)
        setLoadingDetail(false)
        removeTimeoutDetail()

    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
   }


  

   useEffect(()=>{
    const footOpt = async()=>{
        try{
            setLoadingDetail(true);
            loadingTimoutRefDetail.current = setTimeout(() => {
                setLoadingDetail(false);    
            }, 60000);
            
            const squad = await getSelectOptions(8);
            const status = await getSelectOptions(10);
            const legType = await getSelectOptions(7)
            const legLevel = await getSelectOptions(6)

            const response = await getleaguesDetail(selectedRow.id);
            if(!response.ok){
                setMasterErrorsDetail({...setMasterErrorsDetail, 'error': response.error})
            }

            const updatedResponse = response.map(rs =>{
                const lgType = legType?.find(opt => opt.label === rs.leagueType)
                const lgLevel = legLevel?.find(opt => opt.label === rs.leagueLevel)
                const sqd = squad?.find(opt => opt.label === rs.squadType)
                const sts = status?.find(opt => opt.label === rs.status)
              
                return {
                    ...rs,
                    leagueType: lgType,
                    leagueLevel: lgLevel,
                    squadType: sqd,
                    status: sts,
                    
                }
            }) 

            setRowDataDetail(updatedResponse)
            setLoadingDetail(false)
            removeTimeoutDetail()

            setSquadTypeOptions(squad);
            setStatusOptions(status);
           
            setLeagueTypeOptions(legType)
            setLeagueLevelOptions(legLevel)
           

            setIsLoading(false)
        } catch(err){
            console.log('Error fetching rowData: ', err)
        }
    };

    footOpt()
    
   }, [selectedRow])

   //method to excute when search button clicked
   const showSearchDivDetail = () => {
        if(showSearchDetail){
            setShowSearchDetail(false)
        } else{
            setShowSearchDetail(true)
        }
   }

   //method to be excuted when add button click
   const showAddDivDetail = () => {
        clearAddDetail()
        setModuleTitleDetail('Create New record')
        setIsEditDetail(false)
        if(showAddDetail){
            setShowAddDetail(false)
        } else{
            setShowAddDetail(true)
        }
    }

    //methods to be excuted when copy button clicks
    const showCopyDivDetail = () =>{
        setModuleTitleDetail('Copy & Create record')
        setIsEditDetail(false)
        if(showAddDetail){
            setShowAddDetail(false)
        } else{
            setShowAddDetail(true)
            setMasterDataDetail(selectedRowsDetail[0]) 
            setClubNameF(selectedRowsDetail[0]?.clubName)  
            setClubIdF(selectedRowsDetail[0]?.clubId) 

               
        }
        
    }
    //methos to be execute when edit button clicks
    const showEditDivDetail = () =>{
        setModuleTitleDetail('Edit a record')
        setIsEditDetail(true)
        if(showAddDetail){
            setShowAddDetail(false)
        } else{
            setShowAddDetail(true)
            setMasterDataDetail(selectedRowsDetail[0]) 
            setClubNameF(selectedRowsDetail[0]?.clubName)
            setClubIdF(selectedRowsDetail[0]?.clubId) 

        }
        
    }

    //method to be executed when delete button clicks
    const showDeleteDivDetail = (e) =>{
        setShowPopupDetail(true);
    }

     //method to be executed when filter button clicks
     const handleshowFilter = (e) =>{
        if(filterRef.current){
            filterRef.current.handleFilter();
        }
        setShowFilter(true)
    }

    //method to be executed when filter button clicks
    const handlehideFilter = (e) =>{
        setShowFilter(false)
    }
    
    

    //method to be executed when cancel button clicks
    const handleCancelDetail = () =>{
        setShowPopupDetail(false)
    }


    //method to be executed when cancel button of search module  clicks
   const cancelSearchDetail = () =>{
        setShowSearchDetail(false)
   }

   //method to be excuted when cancel button of add module clicks
   const cancelAddDetail = () =>{
        setShowAddDetail(false)
   }
 //handle master search data change
 const handleMasterDataDetailSeachChange = (e)=>{
    const {name, value} = e.target;
    setMasterDataDetailSearch({ ...masterDataDetailSearch, [name]: value})
}


//handle master search Select data change
const handleMasterDataDetailSeachSelectChange = (selectedOption, actionMeta)=>{
    const {name} = actionMeta;
    setMasterDataDetailSearch({ ...masterDataDetailSearch, [name]: selectedOption})
}

 //handle master data change
const handleMasterDataDetailChange = (e) =>{
     const {name, value} = e.target;
     setMasterDataDetail({ ...masterDataDetail, [name]: value})
}

//handle master data change
const handleClubNameChange = (e) =>{
    setClubNameF(e.target.value)
    setMasterDataDetail({ ...masterDataDetail, clubName: clubNameF })
}
 //handle master data Select change
 const handleMasterDataDetailSelectChange = async (selectedOption, actionMeta) =>{
    const {name } = actionMeta;
    
   setMasterDataDetail({ ...masterDataDetail, [name]: selectedOption})

   
      
   
}
   // handle seach button actions
   const submitSearchDetail = async () =>{
    try{
        setLoadingDetail(true);
        loadingTimoutRefDetail.current = setTimeout(() => {
            setLoadingDetail(false);
        }, 60000);

       
        const response = await getleaguesDetail(masterDataDetailSearch);
        if(!response.ok){
           setMasterErrorsDetail({...setMasterErrorsDetail, 'error': response.error})
       }


        setRowDataDetail(response)
        setShowSearchDetail(false)
        setLoadingDetail(false);
        removeTimeoutDetail(); 

    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
   }
    
    //method to crear the seach module fields
    const clearSearchDetail = () =>{
        setMasterDataDetailSearch({
            id: '',
            leagueId: '',
            leagueName: '',
            clubId:'',
            clubIdFull:'',
            clubName: '',
            fromDate: '',
            toDate:'',
            squadType: '',
            leagueType:'',
            leagueLevel:'',
            status: '',
        })
    }
 
 
    //method to clear the add module fields
    const clearAddDetail = () =>{
     setMasterDataDetail({
        id: '',
        leagueId: '',
        leagueName: '',
        clubId:'',
        clubIdFull:'',
        clubName: '',
        fromDate: '',
        toDate:'',
        squadType: '',
        leagueType:'',
        leagueLevel:'',
        status: '',
        
     });
     setClubNameF('')
     setClubIdF('')
    }

   
   //method to validate the input feilds
   const validateFormDetail = async () =>{
    try{
        await leaguesDetailSchema.validate(masterDataDetail, {abortEarly: false})
        setMasterErrorsDetail({})
        return true
    } catch(err){
        const validationErrors = {};
        err.inner.forEach(error => {
            validationErrors[error.path] = error.message
            console.log(error.message)
        })
        setMasterErrorsDetail(validationErrors)
        
        return false;
    }
   }

   //method to handle the delete button actions
   const handleDeleteDetail = async () =>{
    try{
            for(const rw of selectedRowsDetail){
                const id = rw.id;
                const res =await deleteleagueDetail(id)
                if(!res.ok){
                   setMasterErrorsDetail({...masterErrorsDetail, 'error': res.error})
               }
            }     
            setShowPopupDetail(false)
            refreshDetail()
        } catch(err){
            if(err.response){
                const validationErrors = {};
                validationErrors['error'] = err.response.data.error
                setMasterErrorsDetail(validationErrors)
                
            } else if(err.request){
                console.error(err.request)
                console.log('no respone from server')
            } else{
                console.error('error', err.message)
                console.log('unkown error')
            }
        }
   }

   
  
   //method to handle save button actions
   const submitAddDetail = async (e) =>{
        e.preventDefault();
        const leagueId = selectedRow.id;
        const isValid = await validateFormDetail()
        if(isValid){
    
            try{
                if(isEditDetail){
                    
                    const now = new Date();
                    const updatedAt = now.toISOString();
                    const clubName = clubNameF
                    const clubId = clubIdF
                    const addData = { ...masterDataDetail, clubId, clubName, updatedBy, updatedAt }
                    const res = await editleagueDetail(addData.id, addData)
                    if(!res.ok){
                       setMasterErrorsDetail({...masterErrorsDetail, 'error': res.error})
                   }
                
                    setShowAddDetail(false)
                    refreshDetail()
                } else{
                    
                    const now = new Date();
                    const createdAt = now.toISOString();
                    const updatedAt = now.toISOString();
                    const clubName = clubNameF
                    const clubId = clubIdF
                    const addData = { ...masterDataDetail, clubId, clubName, leagueId,createdAt, createdBy, updatedAt, updatedBy}
                    const res = await addleagueDetail(addData)
                    if(!res.ok){
                        setMasterErrorsDetail({...masterErrorsDetail, 'error': res.error})
                    }
                    clearAddDetail()
                    setShowAddDetail(false)
                    refreshDetail()
                }
                
            } catch(err){
                if(err.response){
                    const validationErrors = {};
                    validationErrors['error'] = err.response.data.error
                    setMasterErrorsDetail(validationErrors)
                    
                } else if(err.request){
                    console.error(err.request)
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    console.log('unkown error')
                }
            }
        } 
        else {
            console.log('validation error: ', )
         }
   }

   
  

   //method to handle export button actions
   const handleExportDetail = () =>{
        const params = {
            fileName: 'leagueDetail_export.csv',
            columnSeparator: ','
        }
        gridApiRefDetail.current.api.exportDataAsCsv(params)
   }

   //metho to handle back
   const handleBackToMaster = ()=>{
        showGoDetail(false)
   }

   // method to close the popup in player detail
   const closeHandler = () =>{
    setShowPlayer(false)
}

    

  
   // search fields
   const searchFieldsDetail = [
        {id: 'id', desc:'ID', name: 'id', type:'number', value:masterDataDetailSearch.leagueId, onChange:handleMasterDataDetailSeachChange},

        {id: 'clubId', desc:'Club ID', name: 'clubId', type:'number', value:masterDataDetailSearch.clubId, onChange:handleMasterDataDetailSeachChange},
        {id: 'clubName', desc:'Club Name', name: 'clubName', type:'text', value:masterDataDetailSearch.clubName, onChange:handleMasterDataDetailSeachChange},

        {id: 'fromDate', desc:'From', name: 'fromDate', type:'date', value:masterDataDetailSearch.from, onChange:handleMasterDataDetailSeachChange},
        {id: 'toDate', desc:'To', name: 'toDate', type:'date', value:masterDataDetailSearch.to, onChange:handleMasterDataDetailSeachChange},
       
        {id: 'squadType', desc:'League Squad', name: 'squadType', type:'Select', options:squadTypeOptions, placeHolder:'Select League Squad...', value:masterDataDetailSearch.squadType, onChange:handleMasterDataDetailSeachSelectChange},
        {id: 'leagueType', desc:'League Type', name: 'leagueType', type:'Select', options:leagueTypeOptions, placeHolder:'Select League Type...', value:masterDataDetailSearch.leagueType, onChange:handleMasterDataDetailSeachSelectChange},
        {id: 'leagueLevel', desc:'League Level', name: 'leagueLevel', type:'Select', options:LeagueLevelOptions, placeHolder:'Select League Level...', value:masterDataDetailSearch.leagueLevel, onChange:handleMasterDataDetailSeachSelectChange},

        {id: 'status', desc:'Status', name: 'status', type:'Select', options:statusOptions, placeHolder:'Select status...', value:masterDataDetailSearch.status, onChange:handleMasterDataDetailSeachSelectChange},

    ];

    
    const addFieldsDetail = [
        {id: 'clubName', desc:'Club Name', name: 'clubName', type:'text',readOnly:false, isGenerate: true, text:'validate', onClick:handleshowFilter, value:clubNameF, onChange:handleClubNameChange},
    
        {id: 'fromDate', desc:'From', name: 'fromDate', type:'date',readOnly:false, isGenerate: false,onClick:'', value:masterDataDetail? masterDataDetail.fromDate:'', onChange:handleMasterDataDetailChange},
        {id: 'toDate', desc:'To', name: 'toDate', type:'date',readOnly:false, isGenerate: false,onClick:'', value:masterDataDetail? masterDataDetail.toDate:'', onChange:handleMasterDataDetailChange},
        {id: 'squadType', desc:'League Squad', name: 'squadType', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:squadTypeOptions, placeHolder:'Select League Squad...', value:masterDataDetail?  masterDataDetail.squadType :'', onChange:handleMasterDataDetailSelectChange},
        {id: 'leagueType', desc:'League Type', name: 'leagueType', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:leagueTypeOptions, placeHolder:'Select League Type...', value:masterDataDetail?  masterDataDetail.leagueType:'', onChange:handleMasterDataDetailSelectChange},
        {id: 'leagueLevel', desc:'League Level', name: 'leagueLevel', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:LeagueLevelOptions, placeHolder:'Select League Level...', value:masterDataDetail?  masterDataDetail.leagueLevel :'', onChange:handleMasterDataDetailSelectChange},

        {id: 'status', desc:'Status', name: 'status', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'Select Status...', value:masterDataDetail?  masterDataDetail.status :'', onChange:handleMasterDataDetailSelectChange},
       
    ];

  

    return(
        <div>
            <ShowDetail showPopup={showPlayer} type='profile' onCancelHandler={closeHandler} content={clickedRow} />
            <Filter showFilterPopup={showFilter} onHideFilterPopup={handlehideFilter}  setStaffId={setClubIdF} fullName={clubNameF} setStaffFullName={setClubNameF} tableName="clubs" ref={filterRef}   />
            <ConfirmationPopup showPopup={showPopupDetail} onDeleteHandler={handleDeleteDetail} onCancelHandler={handleCancelDetail} content="Are you sure you want to delete the selected records?" />  
                        <div className="current-page">
                            <p onClick={handleBackToMaster} className="link"><i className="bx bx-arrow-back"> Leagues</i></p>
                            <p>/</p>
                            <p>Leagues Detail</p>
                        </div>
                <div className="page-content">
                    <Actions title='Adjust League Detail here' isLoading={isloading} refresh={refreshDetail}  showSearchDiv={showSearchDivDetail} isGoDetail={false} showAddDiv={showAddDivDetail} showCopyDiv={showCopyDivDetail} showEditDiv={showEditDivDetail} showDeleteDiv={showDeleteDivDetail} handleExport={handleExportDetail} rowData={rowDataDetail} selectedRows={selectedRowsDetail} />
                    
                    <div className="page-body">

                        {
                            showSearchDetail ?
                            <SearchMod searchFields={searchFieldsDetail} submitSearch={submitSearchDetail} cancelSearch={cancelSearchDetail} clearSearch={clearSearchDetail}/>
                            : ''
                        }
                        
                        <div className="ag-theme-quartz ag-grid-container" >
                            <AgGridReact 
                                gridOptions={gridOptionsDetail}
                                rowData={rowDataDetail} 
                                columnDefs={columnDefs} 
                                rowSelection="multiple" 
                                defaultColDef={defaultColDef}
                                domLayout="autoHeight"
                                onSelectionChanged={onSelectionChangedDetail}
                                pagination={true}
                                paginationPageSize={20}
                                paginationPageSizeSelector={[20, 40]}
                                loadingOverlayComponentFramework={CustomLoadingOverlay}
                                loading={loadingDetail}
                                onGridReady={onGridReadyDetail}
                                ref={gridApiRefDetail}
                                rowClassRules={rowClassRules}
                                alwaysShowVerticalScroll={true}
                                alwaysShowHorizontalScroll={true}
                                onRowSelected={onSelectionChangedDetail}
                               
                            />
                    </div>
                    {
                            showAddDetail &&
                            <AddMod addFields={addFieldsDetail} submitAdd={submitAddDetail} moduleTitle={moduleTitleDetail} masterErrors={masterErrorsDetail} cancelAdd={cancelAddDetail} clearAdd={clearAddDetail} />
                        }
                    
                    </div>
                    <div className="page-footer">
                    </div>

            </div>
            
        </div>
    )
}
export default LeaguesDetail;