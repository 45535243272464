import React from "react";
import Login from "./login";
import { Link } from "react-router-dom"
import "../Components/Body/settings.css"
function Unauthorized(){
    return(
        <div className="unauthorized">
            <h1>Unauthorized</h1>
            <p>You do not have permission to view this page. Please log in.</p>
            <Link to={Login}>Login</Link>
        </div>
    )
}
export default Unauthorized;