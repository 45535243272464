import Menu from "../Components/Header/menu";
import Search from "../Components/Header/search";
import { Link } from "react-router-dom";
import '../Components/Body/settings.css';
import {  useEffect, useRef, useState } from "react";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css'
import getlookupMaster, { addlookupMaster, deletelookupMaster, editlookupMaster } from "../services/lookupService";
import CustomLoadingOverlay from "../Components/Body/loadingOverlay";
import { masterSchema } from '../Validations/lookupValidation'
import ConfirmationPopup from "../Utils/confirmationPopup";
import Actions from "../Components/Header/actions";
import SearchMod from "../Components/Body/searchMod";
import AddMod from "../Components/Body/addMod";
import LookupDetail from "./lookupDetail";


function LookupMaster(){
    // state variables
    const [rowData, setRowData] = useState([])
    const [masterErrors, setMasterErrors] = useState({});
    const [showSearch, setShowSearch] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [masterIdSearch, setMasterIDSearch] = useState('');
    const [descriptionSearch, setDescriptionSearch] = useState('');
    const [moduleTitle, setModuleTitle] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [loading, setLoading] = useState(false);
    const [goDetail, setGoDetail] = useState(false)

    

    //constant variables
    const gridOptions = {loadingOverlayComponent: CustomLoadingOverlay}
    const gridApiRef = useRef(null)
    const loadingTimoutRef = useRef(null);
    const dateFormatter = params =>{
        if(!params.value){
            return '';
        }
        const date = new Date(params.value)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
       }
    
    const [columnDefs] = useState([
        {headerName:'Master ID', field: 'masterId'},
        {headerName:'Description', field: 'value'},
        {headerName: 'Created At', field:'createdAt', valueFormatter: dateFormatter}
    ]);
    const [masterData, setMasterData] = useState({
        masterId: '',
        value: '',
        createdAt:''
    })

    const rowClassRules = {
        'selected-row': (params) => selectedRows.includes(params.data.masterId)
    }

    //use-effect method to persist the selection state and reapply it
    useEffect(() =>{
        if(gridApiRef.current.api){
            gridApiRef.current.api.forEachNode(node =>{
                node.setSelected(selectedRows.includes(node.data))
            })
        }
    }
    ,[rowData, selectedRows])
    
 

    //methods to remove time out 
    const removeTimeout = () =>{
        if(loadingTimoutRef.current){
            clearTimeout(loadingTimoutRef.current)
        } 
    }

    //method to be executed on ag-grid ready, resize the ag-grid column as per screen width
    const onGridReady = (params) =>{
        gridApiRef.current = params;
        params.api.sizeColumnsToFit();
        
        params.api.forEachNode(node =>{
            node.setSelected(selectedRows.includes(node.data))
        })
         
    }

    //defaut ag-grid column attributes
    const defaultColDef = {
        resizable: true,
        sortable: true,
        flex: 1,
        filter: true
    }

    

    //method to handle selected rows of ag-grid change
    const onSelectionChanged = (e) =>{
        const selectedRows = e.api.getSelectedRows();
        setSelectedRows(selectedRows)
        setMasterData(selectedRows[0])              
    }
   

   // method to handle refresh 
   const refresh = async (e) =>{
    try{
        setLoading(true);
        loadingTimoutRef.current = setTimeout(() => {
            setLoading(false);    
        }, 60000);

        const response = await getlookupMaster();
        if(!response.ok){
            setMasterErrors({...masterErrors, 'error': response.error})
           
        }
        setRowData(response)
        setLoading(false)
        removeTimeout()

    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
   }

   //method to excute when search button clicked
   const showSearchDiv = () => {
        if(showSearch){
            setShowSearch(false)
        } else{
            setShowSearch(true)
        }
   }

   //method to be executed when go detail button clicked
   const showGoDetail = (arg) =>{
    setGoDetail(arg);
   }

   //method to be excuted when add button click
   const showAddDiv = () => {
        clearAdd()
        setModuleTitle('Create New record')
        setIsEdit(false)
        if(showAdd){
            setShowAdd(false)
        } else{
            setShowAdd(true)
        }
    }

    //methods to be excuted when copy button clicks
    const showCopyDiv = () =>{
        setModuleTitle('Copy & Create record')
        setIsEdit(false)
        if(showAdd){
            setShowAdd(false)
        } else{
            setShowAdd(true)
            setMasterData(selectedRows[0]) 
        }
        
    }
    //methos to be execute when edit button clicks
    const showEditDiv = () =>{
        setModuleTitle('Edit a record')
        setIsEdit(true)
        if(showAdd){
            setShowAdd(false)
        } else{
            setShowAdd(true)
            setMasterData(selectedRows[0]) 
        }
        
    }

    //method to be executed when delete button clicks
    const showDeleteDiv = (e) =>{
        setShowPopup(true);
    }
    

    //method to be executed when cancel button clicks
    const handleCancel = () =>{
        setShowPopup(false)
    }


    //method to be executed when cancel button of search module  clicks
   const cancelSearch = () =>{
        setShowSearch(false)
   }

   //method to be excuted when cancel button of add module clicks
   const cancelAdd = () =>{
        setShowAdd(false)
   }

   //handle master id of seach module change
   const handleMasterIdSearchChange = (e) =>{
        setMasterIDSearch(e.target.value)
   }

  
   //handle value of seach module change
   const handleDescriptionSearchChange = (e) =>{
        setDescriptionSearch(e.target.value)
    }

    //handle master data change
   const handleMasterChange = (e) =>{
        const {name, value} = e.target;
        setMasterData({ ...masterData, [name]: value})
   }

   // handle seach button actions
   const submitSearch = async () =>{
    try{
        setLoading(true);
        loadingTimoutRef.current = setTimeout(() => {
            setLoading(false);
        }, 60000);

        const response = await getlookupMaster(masterIdSearch, descriptionSearch);
        setRowData(response)
        setShowSearch(false)
        setLoading(false);
        removeTimeout(); 

    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
   }
    
   //method to crear the seach module fields
   const clearSearch = () =>{
    setMasterIDSearch('');
    setDescriptionSearch('');
   }

   //method to clear the add module fields
   const clearAdd = () =>{
    setMasterData({
        masterId: '',
        value: '',
        createdAt: ''
    });
   }

   
   //method to validate the input feilds
   const validateForm = async () =>{
    try{
        await masterSchema.validate(masterData, {abortEarly: false})
        setMasterErrors({})
        return true
    } catch(err){
        const validationErrors = {};
        err.inner.forEach(error => {
            validationErrors[error.path] = error.message
        })
        setMasterErrors(validationErrors)
        return false;
    }
   }

   //method to handle the delete button actions
   const handleDelete = async () =>{
    try{
            for(const rw of selectedRows){
                const id = rw.masterId;
                await deletelookupMaster(id)
            }     
            setShowPopup(false)
            refresh()
        } catch(err){
            if(err.response){
                const validationErrors = {};
                validationErrors['error'] = err.response.data.error
                setMasterErrors(validationErrors)
                
            } else if(err.request){
                console.error(err.request)
                console.log('no respone from server')
            } else{
                console.error('error', err.message)
                console.log('unkown error')
            }
        }
   }
  
   //method to handle save button actions
   const submitAdd = async (e) =>{
        e.preventDefault();
        const now = new Date();
        const createdAt = now.toISOString();
        const addData = { ...masterData, createdAt}
        const isValid = await validateForm()
        if(isValid){
           
            try{
                if(isEdit){
                    const res = await editlookupMaster(addData.masterId, addData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'error': res.error})
                    }
                    setShowAdd(false)
                    refresh()
                } else{
                    const res = await addlookupMaster(addData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'error': res.error})
                    }
                    clearAdd()
                    setShowAdd(false)
                    refresh()
                }
                
            } catch(err){
                if(err.response){
                    const validationErrors = {};
                    validationErrors['error'] = err.response.data.error
                    setMasterErrors(validationErrors)
                    
                } else if(err.request){
                    console.error(err.request)
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    console.log('unkown error')
                }
            }
        } 
   }

   
   //method to generate the next id
   const generateMasterID = async (e) =>{
        const response = await getlookupMaster();
        if(response.length > 0){
            const nextID = response[response.length - 1].masterId + 1;
            setMasterData({ ...masterData, masterId: nextID})
        } else{
            setMasterData({ ...masterData, masterId: 1})
        }
       
       
   }

   //method to handle export button actions
   const handleExport = () =>{
        const params = {
            fileName: 'lookumaster_export.csv',
            columnSeparator: ','
        }
        gridApiRef.current.api.exportDataAsCsv(params)
   }

   

   // search fields
   const searchFields = [
        {id: 'masterId', desc:'Master ID', name: 'masterId', type:'number', value:masterIdSearch, onChange:handleMasterIdSearchChange},
        {id: 'value', desc:'Description', name: 'value', type:'text', value:descriptionSearch, onChange:handleDescriptionSearchChange}
    ];

    
    const addFields = [
        {id: 'masterId', desc:'Master ID', name: 'masterId', type:'number', readOnly:isEdit, isGenerate: true, text:'Generate', onClick:generateMasterID, value:masterData ? masterData.masterId: '', onChange:handleMasterChange},
        {id: 'value', desc:'Description', name: 'value', type:'text', readOnly:false, isGenerate: false,onclick:'', value:masterData ? masterData.value : '', onChange:handleMasterChange}
    ];

    return(
        <div>
            <ConfirmationPopup showPopup={showPopup} onDeleteHandler={handleDelete} onCancelHandler={handleCancel} content="Are you sure you want to delete the selected records?" />  
            <Menu />
            <Search />
            

            {
                goDetail ? <LookupDetail selectedRow={selectedRows[0]} showGoDetail={showGoDetail} />
                :
                <div>
                    <div className="current-page">
                        <p ><Link to="/dashboard" className="link">Dashboard</Link></p>
                        <p>/</p>
                        <p>Lookup Master</p>
                    </div>
                    <div className="page-content">
                        <Actions title='Adjust lookup Master here' refresh={refresh} showSearchDiv={showSearchDiv} showGoDetailDiv={showGoDetail} showAddDiv={showAddDiv} showCopyDiv={showCopyDiv} showEditDiv={showEditDiv} showDeleteDiv={showDeleteDiv} handleExport={handleExport} rowData={rowData} selectedRows={selectedRows} />
                        
                        <div className="page-body">

                            {
                                showSearch ?
                                <SearchMod searchFields={searchFields} submitSearch={submitSearch} cancelSearch={cancelSearch} clearSearch={clearSearch}/>
                                : ''
                            }
                            
                            <div className="ag-theme-quartz" style={{ height: '100%', width: '100%', margin:'6px 0'}}>
                                <AgGridReact 
                                    gridOptions={gridOptions}
                                    rowData={rowData} 
                                    columnDefs={columnDefs} 
                                    rowSelection="multiple" 
                                    defaultColDef={defaultColDef}
                                    domLayout="autoHeight"
                                    onSelectionChanged={onSelectionChanged}
                                    pagination={true}
                                    paginationPageSize={20}
                                    paginationPageSizeSelector={[20, 40]}
                                    loadingOverlayComponentFramework={CustomLoadingOverlay}
                                    loading={loading}
                                    onGridReady={onGridReady}
                                    ref={gridApiRef}
                                    rowClassRules={rowClassRules}
                                />
                        </div>

                        {
                                showAdd &&
                                <AddMod addFields={addFields} submitAdd={submitAdd} moduleTitle={moduleTitle} masterErrors={masterErrors} cancelAdd={cancelAdd} clearAdd={clearAdd} />
                            }
                        
                        </div>
                        <div className="page-footer">
                        </div>

                    </div>
                </div>
                
            }
            
            
            
        </div>
    );
}

export default LookupMaster;