import {axiosInstance} from "../Utils/axiosInstance"

export default function getMatchs(mch){
    return axiosInstance.get('/matchs', {
        params:{
            id: mch ? mch.id : null,
            fixtureId: mch ? mch.fixtureId : null,
            leagueId: mch? mch.leagueId: null,
            leagueName: mch? mch.leagueName?.label ? mch.leagueName.label: mch.leagueName ? mch.leagueName: null: null,
            home: mch? mch.home?.label ? mch.home?.label : mch.home ? mch.home : null : null,
            away: mch? mch.away?.label ? mch.away?.label : mch.away ? mch.away : null : null,
            stadium: mch? mch.stadium: null,
            dop: mch? mch.dop: null,
            url1: mch? mch.url1: null,
            url2: mch? mch.url2: null,
            url3: mch? mch.url3: null,
            createdAt: mch? mch.createdAt: null,
            createdBy: mch? mch.createdBy: null,
            updatedAt: mch? mch.updatedAt: null,
            updatedBy: mch? mch.updatedBy: null,
            dopLatest: mch? mch?.dopLatest: null,
            homeSearch: mch? mch.homeSearch: null
        }
        
    })
        .then(response => response.data)
        .catch(err => {
            console.log('error in service:',err.message)
            throw err.response?.data || err.message; 
        })
}


export  function addMatch(mch){
    return axiosInstance.post('/matchs',{
        fixtureId: mch.fixtureId?.value,
        url1: mch.url1? mch.url1 : null,
        url2: mch.url2? mch.url2: null,
        url3: mch.url3? mch.url3: null,
        createdAt: mch.createdAt,
        createdBy: mch.createdBy,
        updatedBy: mch.updatedBy
       
    }, { withCredentials: true }).then(response => response.data)
}

export function editMatchs(id, mch){
    return axiosInstance.put('/matchs/' + id + '/',{
            fixtureId: mch.fixtureId?.value,
            url1: mch.url1,
            url2: mch.url2,
            url3: mch?.url3,
            updatedAt: mch.updatedAt,
            updatedBy: mch.updatedBy,
       }, { withCredentials: true })
    .then(response => response.data)
}

export function deleteMatch(id){
    return axiosInstance.delete('/matchs/' + id + '/',{ 
        withCredentials: true 
    }).then(response => response.data)
}
