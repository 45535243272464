import './body.css'
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
//import getMatchs from '../../services/matchService'
import moment from 'moment'


//const backEndUrl = "http://localhost:8000/media/"

const backEndUrl = "https://footballback.zeaye.com/media/"

function Body({matchs, setMatchs}){
    const [itemsPerPage, setItemsPerPage] = useState(12);
   // const [matchs, setMatchs] = useState()
 
    const navigate = useNavigate()

   
    function handleLoadMore(){
        setItemsPerPage(itemsPerPage => itemsPerPage + 6)
    }

   

    const getCurrentPageItems = () => {
        const startIndex = 0;
        const endIndex = startIndex + itemsPerPage;
        //return highlights.slice(startIndex, endIndex);
        return matchs?.slice(startIndex, endIndex)
    };

    const getRelativeDate = (dateString) =>{
        const parsedDate = moment(dateString)
        return parsedDate.fromNow();
    }


    const handleNagivate = (hActive) =>{
        navigate('/highlight', { 
            state: {
                allMatchs: matchs,
                activeH: hActive
            } 
        })
    }
    
   
    return (
        <div className="my-container">
            <div className="body">
                <ul className="row">
                     {getCurrentPageItems()?.map((h) =>(
                            
                            <li className="col-1-3" key={h.matchId}>
                               
                                    <div className="my-card">
                                            <h3 className="my-card-title">
                                               {h.leagueName} 
                                            </h3>
                                            <div className='my-card-link' onClick={() => handleNagivate(h)}>
                                                <div className="my-card-body">
                                                        <h4 className="home">{h.home}</h4>
                                                        <h4 className="vs">Vs</h4>
                                                        <h4 className="away">{h.away}</h4>
                                                 </div>
                                            </div>
                                            {/* <Link to={`/highlight?id=${h.matchId}&category=${h.leagueName}`} className='my-card-link'>  */}
                                                     
                                                
                                            {/* </Link> */}
                                            <div className="my-card-fotter">
                                                <h5 className="date">{ getRelativeDate(h.dop)}</h5>
                                                <img src={`${backEndUrl}${h.logo}`} alt={h.leagueName} className='logoImg2' />
                                            </div>
                                    </div>
                              
                               
                            </li>
                     
        
                    ))}
                </ul>

                <div className='LoadMore'>
                        <button className='btn-more' onClick={handleLoadMore}>Show More Highlights</button>
                </div>
                    
            </div>

           

        </div>
        
    )
}
export default Body