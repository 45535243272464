import{axiosInstance}  from "../Utils/axiosInstance"

export default function getMatchAnalysis(mch){
    
    return axiosInstance.get('/matchAnalysis', {
        params:{
            id: mch ? mch.id : null,
            fixtureId: mch ? mch.fixtureId : null,
            leagueId: mch? mch.leagueId: null,
            leagueName: mch? mch.leagueName.label ? mch.leagueName.label: mch.leagueName ? mch.leagueName: null: null,
            home: mch? mch.home?.label: null,
            away: mch? mch.away?.label: null,
            stadium: mch? mch.stadium: null,
            dop: mch? mch.dop: null,
            url1: mch? mch.url1: null,
            url2: mch? mch.url2: null,
            url3: mch? mch.url3: null,
            createdAt: mch? mch.createdAt: null,
            createdBy: mch? mch.createdBy: null,
            updatedAt: mch? mch.updatedAt: null,
            updatedBy: mch? mch.updatedBy: null,
        }
        
    })
        .then(response => response.data)
}


export  function addMatchAnalysis(mch){
    return axiosInstance.post('/matchAnalysis',{
        matchId: mch?.matchId,
        analysis: mch.analysis? mch.analysis : null,
        createdAt: mch.createdAt,
        createdBy: mch.createdBy,
        updatedBy: mch.updatedBy
       
    }, { withCredentials: true })
    .then(response => response.data)
}

export function editMatchAnalysis(id, mch){
    return axiosInstance.put('/matchAnalysis/' + id + '/',{
            matchId: mch.match?.matchId,
            analysis: mch.analysis,
            updatedAt: mch.updatedAt,
            updatedBy: mch.updatedBy,
       }, { withCredentials: true })
    .then(response => response.data)
}

export function deleteMatchAnalysis(id){
    return axiosInstance.delete('/matchAnalysis/' + id + '/',{
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }, { withCredentials: true })
    .then(response => response.data)
}
