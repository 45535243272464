import {axiosInstance} from '../Utils/axiosInstance'

export default function getlookupMaster(mId, val){
    return axiosInstance.get('/lookupmaster', {
        params:{
            masterId: mId,
            value: val
        }
    })
        .then(response => response.data)
}


export  function addlookupMaster(lk){
    
    return axiosInstance.post('/lookupmaster',{
        masterId: lk.masterId,
        value: lk.value,
        createdAt: lk.createdAt
    },{ withCredentials: true })
    .then(response => response.data)
}

export function editlookupMaster(id, lk){
    return axiosInstance.put('/lookupmaster/' + id + '/',{
            value: lk.value,
    },{ withCredentials: true })
    .then(response => response.data)
}

export function deletelookupMaster(id){
    return axiosInstance.delete('/lookupmaster/' + id + '/',{
         withCredentials: true
    })
    .then(response => response.data)
}

//for lookup detail
export  function getlookupDetail(mId, dId, val){
    return axiosInstance.get('/lookupdetail', {
        params:{
            masterId: mId,
            detailId: dId,
            value: val
        }
    })
        .then(response => response.data)
}


export  function addlookupDetail(lk){
    return axiosInstance.post('/lookupdetail',{
        masterId: lk.masterId,
        detailId: lk.detailId,
        value: lk.value,
        createdAt: lk.createdAt
    },{ withCredentials: true })
    .then(response => response.data)
}

export function editlookupDetail(id, lk){
    return axiosInstance.put('/lookupdetail/' + id + '/',{
            value: lk.value,
    }, { withCredentials: true })
    .then(response => response.data)
}

export function deletelookupDetail(id){
    return axiosInstance.delete('/lookupdetail/' + id + '/',{
        withCredentials: true 
    })
    .then(response => response.data)
}