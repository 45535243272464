import React from "react";
const backEndUrl = "http://localhost:8000/media/"

function ShowDetail({showPopup, onCancelHandler, content, type}){
    return(
        <div className={`popup ${showPopup ? 'show': ''}`}>
            <div className="popup-content">
                <div className="popup-hdr">
                    <p>Detail</p>
                    <button className="close-button" onClick={onCancelHandler}><i className='bx bx-x'></i></button>
                </div>
                <div className="popup-body">
                    <ul className="row">
                        <li className="col-1-2">
                            {
                                type === 'profile'?
                                    content?.playerPhoto ?
                                        <div>
                                            <img src={`${backEndUrl}${content?.playerPhoto}`} alt={`${content?.firstName} ${content?.lastName}`} className="player-detail-img"/>
                                            <p className="player-detail-value-left">{content? content.fullName? content.fullName : content.staffName: ''}</p>
                                        </div>
                                    :
                                        <div>
                                            <i className="bx bx-user-circle grid-icon-detail"  ></i>
                                            <p className="player-detail-value-left">{content? content.fullName? content.fullName : content.staffName: ''}</p>
                                        </div>
                                :
                                content?.logo ?
                                        <div>
                                            <img src={`${backEndUrl}${content?.logo}`} alt={content?.name} className="player-detail-img"/>
                                            <p className="player-detail-value-left">{content?.name}</p>
                                        </div>
                                    :
                                        <div>
                                            <i className="bx bx-user-circle grid-icon-detail"  ></i>
                                            <p className="player-detail-value-left">{content?.name}</p>
                                        </div>
                            }
                            
                           
                        </li>
                        <li className="col-1-2">
                            {
                                type === 'profile'?
                                <div>
                                    <div className="player-detail-div">
                                        <span className="player-detail-label">Position</span>
                                        <p className="player-detail-value">{content ? content.position.label ? content.position.label : content.position: ''}</p>
                                    </div>
                                    <div className="player-detail-div">
                                        <span className="player-detail-label">Date of Birth</span>
                                        <p className="player-detail-value">{content?.dob}</p>
                                    </div>
                                
                                    <div className="player-detail-div">
                                        <span className="player-detail-label">Height</span>
                                        <p className="player-detail-value">{content?.height}</p>
                                    </div>
                                    
                                    <div className="player-detail-div">
                                        <span className="player-detail-label">Citizen ship</span>
                                        <p className="player-detail-value">{content ? content.citizenShip.label ?  content.citizenShip.label : content.citizenShip : '' }</p>
                                    </div>
                                    <div className="player-detail-div">
                                        <span className="player-detail-label">Nationality</span>
                                        <p className="player-detail-value">{content ? content.currentNational.label ? content.currentNational.label: content.currentNational: ''}</p>
                                    </div>
                                    <div className="player-detail-div">
                                        <span className="player-detail-label">Preffered Foot</span>
                                        <p className="player-detail-value">{content ? content.foot.label ? content.foot.label : content.foot: ''}</p>
                                    </div>
                                    
                                    <div className="player-detail-div">
                                        <span className="player-detail-label">Status</span>
                                        <p className="player-detail-value">{content ? content.status.label ? content.status.label : content.status : ''}</p>
                                    </div>

                                </div>
                                :
                                <div>
                                    {content?.country &&
                                        <div className="player-detail-div">
                                            <span className="player-detail-label">Country</span>
                                            <p className="player-detail-value">{content?.country.label}</p>
                                        </div>
                                    }
                                    
                                    {content?.city &&
                                        <div className="player-detail-div">
                                            <span className="player-detail-label">City</span>
                                            <p className="player-detail-value">{content?.city}</p>
                                        </div>
                                    }
                                   
                                   {
                                    content?.doe && 
                                    <div className="player-detail-div">
                                        <span className="player-detail-label">Founded Date</span>
                                        <p className="player-detail-value">{content?.doe}</p>
                                    </div>
                                   }
                                    
                                    {content?.stadium && 
                                        <div className="player-detail-div">
                                            <span className="player-detail-label">Stadium</span>
                                            <p className="player-detail-value">{content?.stadium}</p>
                                        </div>
                                    }
                                    
                                    {
                                        content?.stadiumSeats && 
                                        <div className="player-detail-div">
                                            <span className="player-detail-label">Stadium Seats</span>
                                            <p className="player-detail-value">{content?.stadiumSeats}</p>
                                        </div>
                                    }

                                    {content?.type &&
                                        <div className="player-detail-div">
                                            <span className="player-detail-label">Type</span>
                                            <p className="player-detail-value">{content?.type.label}</p>
                                        </div>
                                    }
                                    
                                    {
                                        content?.nickName && 
                                        <div className="player-detail-div">
                                            <span className="player-detail-label">Nick Name</span>
                                            <p className="player-detail-value">{content?.nickName}</p>
                                        </div>
                                    }
                                     {
                                        content?.clubsNbr && 
                                        <div className="player-detail-div">
                                            <span className="player-detail-label">Number of clubs</span>
                                            <p className="player-detail-value">{content?.clubsNbr}</p>
                                        </div>
                                    }
                                    
                                    

                                </div>
                            }
                                
                            
                        </li>

                    </ul>
                    
                </div>
                <div className="popup-buttons">
                    <button onClick={onCancelHandler} className="popup-no">Ok</button>
                </div>
            </div>

        </div>
    )
}
export default ShowDetail;