import React from 'react'
import Menu from '../Components/Header/menu';
import Search from '../Components/Header/search';
import HighlightMatchFact from '../Components/Body/highlightMatchfact';


function CreateHl(){
    return (
        <div className='my-container'>
            <Menu />
            <Search />
            <HighlightMatchFact />

        </div>
    )
}
export default CreateHl;
