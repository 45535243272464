import React, { useState } from "react";
import { loginSchema } from "../Validations/loginValidation";
import { getUser, login } from "../services/usersService";
import "../Components/Body/user.css"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import logofootball from '../Assets/images/myfootball.png'
import { setUser } from "../slices/authSlice";

function Login({ setIsAuthenticated }){

    const[userName, setUserName] = useState()
    const[passWord, setPassWord] = useState()
    const [masterErrors, setMasterErrors] = useState({});
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const userNameChange = (e) =>{
        setUserName(e.target.value)
    }
    const passwordChange = (e) =>{
        setPassWord(e.target.value)
    }

    const handleKeyDown = (e) =>{
        if(e.key === 'Enter'){
            handleLogin(e)
        }
    }
    //method to validate the input feilds
    const validateForm = async (data) =>{
        try{
            await loginSchema.validate(data, {abortEarly: false})
            setMasterErrors({})
            return true
        } catch(err){
            const validationErrors = {};
            err.inner?.forEach(error => {
                validationErrors[error.path] = error.message
            })
            console.log(validationErrors)
            console.log(err)
            setMasterErrors(validationErrors)
            return false;
        }
    }


    const cancelLogin = (e) =>{
        e.preventDefault()
        navigate('/')
    }
    const handleLogin = async (e) =>{
        e.preventDefault()
        const data ={
            userName: userName,
            passWord: passWord
        }
        const isValid = await validateForm(data)
        if(isValid){
            try{
                const res = await login(data)
               // const token = res.key;
                console.log('respons:', res)
                const token = res.access
                const tokenRefresh = res.refresh

                localStorage.setItem('fb-token', token)
                localStorage.setItem('fb-token-refresh', tokenRefresh)

                const usr = await getUser(token)
                dispatch(setUser({detail:usr, token: token, tokenRefresh:tokenRefresh}))
                
                localStorage.setItem('fb-user', JSON.stringify(usr))

                navigate('/dashboard')
                
            } catch(err){
               // console.log('Error in login:', err)
                if (err.non_field_errors) {
                    setMasterErrors({'loginError': err.non_field_errors[0]});  // Extract the first error message from the array
                } else {
                    setMasterErrors({'loginError': err.message || "Login failed. Please check your credentials."})
                }
              
            }
            
        }
    }
   
    
    return (
        <div className="login-container">
            <form  className="login">
                <div className="login-hdr">
                    <Link to="/" className='link'><img src={logofootball} alt='myFootball' className='logo'/></Link>
                    <p className="login-title">Welcome Back</p>
                </div>
                {masterErrors.loginError && <p className="form-error-login-hdr">{masterErrors.loginError}</p>}
                <div className="log-input">
                    {masterErrors['userName'] && <span className="form-error-login">{masterErrors['userName']}</span>}
                   <div className="login-div">
                        <i className="bx bx-user login-icon"></i>
                        <input name="userName" value={userName} onChange={userNameChange} placeholder="user Name" />
                    </div> 
                </div>
                <div className="log-input">
                    {masterErrors['passWord'] && <span className="form-error-login">{masterErrors['passWord']}</span>}
                <div className="login-div"><i className="bx bx-key login-icon"></i><input type="password" name="passWord" value={passWord} onChange={passwordChange} onKeyDown={handleKeyDown} placeholder="pass word" /></div> 
                </div>
               
                <div className="log-input-btn">
                    <button type="submit" className="btn-login" onClick={handleLogin}>Login</button>
                    <button type="submit" className="btn-login" onClick={cancelLogin}>Cancel</button>
                </div>
            </form>
        </div>
    )
}

export default Login;