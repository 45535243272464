import {axiosInstance} from "../Utils/axiosInstance"

export default function getleaguesDetail(lg){
    
    return axiosInstance.get('/leaguesDetail', {
        params:{
            id: lg ? lg.id : null,
            leagueId: lg ? lg.leagueId : null,
            clubId: lg ? lg.clubId : null,
            clubName: lg? lg.clubName: null,
            fromDate: lg? lg.fromDate: null,
            toDate: lg? lg.toDate: null,
            squadType: lg? lg.squadType?.label: null,
            leagueType: lg? lg.leagueType?.label: null,
            leagueLevel: lg? lg.leagueLevel?.label: null,
            status: lg? lg.status?.label: null,
            createdAt: lg? lg.createdAt: null,
            createdBy: lg? lg.createdBy: null,
            updatedAt: lg? lg.updatedAt: null,
            updatedBy: lg? lg.updatedBy: null,
        }
    })
        .then(response => response.data)
}


export  function addleagueDetail(lg){
    return axiosInstance.post('/leaguesDetail',{
        leagueId: lg.leagueId,
        clubId: lg.clubId,
        fromDate: lg.fromDate,
        toDate: lg.toDate,
        squadType: lg.squadType?.value,
        leagueType: lg.leagueType?.value,
        leagueLevel: lg.leagueLevel?.value,
        status: lg.status?.value,
        createdAt: lg.createdAt,
        createdBy: lg.createdBy,
        updatedBy: lg.updatedBy
        
    },{ withCredentials: true })
    .then(response => response.data)
}

export function editleagueDetail(id, lg){
    return axiosInstance.put('/leaguesDetail/' + id + '/',{
            clubId: lg.clubId,
            fromDate: lg.fromDate,
            toDate: lg.toDate,
            squadType: lg.squadType?.value,
            leagueType: lg.leagueType?.value,
            leagueLevel: lg.leagueLevel?.value,
            status:lg.status?.value,
            updatedAt:lg.updatedAt,
            updatedBy:lg.updatedBy,
       },{ withCredentials: true })
    .then(response => response.data)
}

export function deleteleagueDetail(id){
    return axiosInstance.delete('/leaguesDetail/' + id + '/',
        { withCredentials: true }
    ).then(response => response.data)
}
