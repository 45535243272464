import * as yup from 'yup'
const FILE_SIZE = 1024 * 1024 * 2
const SUPPORTED_FORMATS = ['image/jpg','image/jpeg', 'image/gif', 'image/png']


export const clubsSchema = yup.object().shape({
    clubId: yup.string().typeError('* Club ID must be a string').required('* Club Id required'),
    type: yup.object().required('* Club type required'),
    profile: yup.mixed()
        .nullable()
        .test('fileSize', 'Upload an image size < 2MB', value => {return !value ||(value && value.size <= FILE_SIZE)})
        .test('fileFormat', 'Unsupported Format, only jpg, jpeg, gif, png are supported', value => {return !value || (value && SUPPORTED_FORMATS.includes(value.type))}),
    name: yup.string().required('* Name required'),
    stadium: yup.string().required('* Stadium required'),
    stadiumSeats: yup.string().required('* Stadium Seats required'),
    doe: yup.date().required('* Foundation date required'),
    country: yup.object().required('* Country required'),
    city: yup.string().required('* City required'),

});

