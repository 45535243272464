import {axiosInstance} from "../Utils/axiosInstance"
export default function getFixtures(fx){
    return axiosInstance.get('/fixtures', {
        params:{
            id: fx ? fx.id : null,
            fixtureId: fx ? fx.fixtureId : null,
            leagueId: fx? fx.leagueId: null,
            leagueName: fx? fx.leagueName: null,
            home: fx? fx.home?.label: null,
            away: fx? fx.away?.label: null,
            country: fx? fx.country?.label: null,
            stadium: fx? fx.stadium: null,
            dop: fx? fx.dop: null,
            createdAt: fx? fx.createdAt: null,
            createdBy: fx? fx.createdBy: null,
            updatedAt: fx? fx.updatedAt: null,
            updatedBy: fx? fx.updatedBy: null,
            dateDiff: fx?  fx.dateDiff: null,
            dopLatest: fx? fx?.dopLatest: null
        }
        
    })
        .then(response => response.data)
}


export  function addFixture(fx){
    return axiosInstance.post('/fixtures',{
        fixtureId: fx.fixtureId,
        leagueId: fx.leagueId?.value,
        home: fx.home?.value,
        away: fx.away?.value,
        country: fx.country?.label,
        stadium: fx.stadium ? fx.stadium : null,
        dop: fx.dop,
        createdAt: fx.createdAt,
        createdBy: fx.createdBy,
        updatedBy: fx.updatedBy
       
    },{ withCredentials: true })
    .then(response => response.data)
}

export function editFixture(id, fx){
    return axiosInstance.put('/fixtures/' + id + '/',{
            leaguId: fx.leaguId,
            home: fx.home?.value,
            away: fx.away?.value,
            country: fx.country?.label,
            stadium: fx.stadium,
            dop: fx.dop,
            updatedAt: fx.updatedAt,
            updatedBy: fx.updatedBy,
       }, { withCredentials: true })
    .then(response => response.data)
}

export function deleteFixture(id){
    return axiosInstance.delete('/fixtures/' + id + '/',
        { withCredentials: true }
    ).then(response => response.data)
}
