import {axiosInstance} from "../Utils/axiosInstance"

export default function getclubsDetail(club){
    return axiosInstance.get('/clubsDetail', {
        params:{
            id: club ? club.id : null,
            clubId: club ? club.clubId : null,
            staffId: club ? club.staffId : null,
            staffName: club? club.staffName: null,
            position: club? club.position?.label? club.position.label : club.position? club.position : null : null,
            shirtNbr: club? club.shirtNbr: null,
            fromDate: club? club.fromDate: null,
            toDate: club? club.toDate: null,
            squadType: club? club.squadType?.label: null,
            leagueType: club? club.leagueType?.label: null,
            leagueLevel: club? club.leagueLevel?.label: null,
            salary: club? club.salary: null,
            exitDate: club? club.exitDate: null,
            isCaptain: club? club.isCaptain?.label: null,
            status: club? club.status?.label? club.status.label : club.status? club.status : null : null,
            createdAt: club? club.createdAt: null,
            createdBy: club? club.createdBy: null,
            updatedAt: club? club.updatedAt: null,
            updatedBy: club? club.updatedBy: null,
        }
    })
        .then(response => response.data)
}


export  function addclubDetail(club){
    return axiosInstance.post('/clubsDetail',{
        clubId: club.clubId,
        staffId: club.staffId,
        shirtNbr: club.shirtNbr,
        fromDate: club.fromDate,
        toDate: club.toDate,
        squadType: club.squadType?.value,
        leagueType: club.leagueType?.value,
        leagueLevel: club.leagueLevel?.value,
        salary: club.salary? club.salary: null,
        exitDate: club.exitDate? club.exitDate: null,
        status: club.clubStatus?.value,
        isCaptain: club.isCaptain?.value,
        createdAt: club.createdAt,
        createdBy: club.createdBy,
        updatedBy: club.updatedBy
        
    },{ withCredentials: true })
    .then(response => response.data)
}

export function editclubDetail(id, club){
    return axiosInstance.put('/clubsDetail/' + id + '/',{
            staffId: club.staffId,
            shirtNbr: club.shirtNbr,
            fromDate: club.fromDate,
            toDate: club.toDate,
            squadType: club.squadType?.value,
            leagueType: club.leagueType?.value,
            leagueLevel: club.leagueLevel?.value,
            salary: club.salary,
            exitDate: club.exitDate,
            isCaptain: club.isCaptain?.value,
            status:club.clubStatus?.value,
            updatedAt:club.updatedAt,
            updatedBy:club.updatedBy,
       },{ withCredentials: true })
    .then(response => response.data)
}

export function deleteclubDetail(id){
    return axiosInstance.delete('/clubsDetail/' + id + '/',{
        withCredentials: true 
    })
    .then(response => response.data)
}
