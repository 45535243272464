import {axiosInstance} from "../Utils/axiosInstance"

export default function getPlayers(player){
    return axiosInstance.get('/players', {
        params:{
            id: player ? player.id : null,
            playerId: player ? player.playerId : null,
            playerPhoto: player? player.playerPhoto: null,
            firstName: player? player.firstName: null,
            middleName: player? player.middleName: null,
            lastName: player? player.lastName: null,
            fullName: player? player.fullName: null,
            dob: player? player.dob: null,
            placeOfBirth: player? player.placeOfBirth: null,
            citizenShip: player? player.citizenShip?.label: null,
            currentNational: player? player.currentNational?.label: null,
            height: player? player.height: null,
            weight: player? player.weight: null,
            marketValue: player? player.marketValue: null,
            position: player? player.position?.label: null,
            foot: player? player.foot?.label: null,
            status: player? player.status?.label: null
        }
    })
        .then(response => response.data)
}

export function getstaffByStatus(status){
    return axiosInstance.get('/players', {
        params:{
            status: status,
        }
    })
        .then(response => response.data)
}



export  function addPlayer(player){
    const data ={
        playerId: player.playerId,
        playerPhoto: player.playerPhoto,
        firstName: player.firstName,
        middleName: player.middleName,
        lastName: player.lastName,
        fullName: player.fullName,
        dob: player.dob,
        placeOfBirth:player.placeOfBirth,
        citizenShip: player.citizenShip?.label,
        currentNational: player.currentNational?.label,
        height: player.height,
        weight: player.weight,
        marketValue: player.marketValue,
        position: player.position?.value,
        foot: player.foot?.value,
        status: player.status?.value,
        createdAt: player.createdAt
    }
    const formData = createFormData(data)
    return axiosInstance.post('/players',formData,
    { withCredentials: true }
    ).then(response => response.data)
}

export function editPlayer(id, player, profile){
    let data;
    if(profile){
         data ={
            playerPhoto: profile,
            firstName: player.firstName,
            middleName: player.middleName,
            lastName: player.lastName,
            fullName: player.fullName,
            dob: player.dob,
            placeOfBirth:player.placeOfBirth,
            citizenShip: player.citizenShip?.label,
            currentNational: player.currentNational?.label,
            height: player.height,
            weight: player.weight? player.weight: null,
            marketValue: player.marketValue,
            position: player.position?.value,
            foot: player.foot?.value,
            status: player.status?.value
        }

    } else{
        data ={
            firstName: player.firstName,
            middleName: player.middleName,
            lastName: player.lastName,
            fullName: player.fullName,
            dob: player.dob,
            placeOfBirth:player.placeOfBirth,
            citizenShip: player.citizenShip?.label,
            currentNational: player.currentNational?.label,
            height: player.height,
            weight: player.weight? player.weight: null,
            marketValue: player.marketValue,
            position: player.position?.value,
            foot: player.foot?.value,
            status: player.status?.value
        }
    }
    
    const formData = createFormData(data)

    return axiosInstance.put('/players/' + id + '/',
        formData,
        { withCredentials: true })
        .then(response => response.data)
}

export function deletePlayer(id){
    return axiosInstance.delete('/players/' + id + '/',{ 
        withCredentials: true 
    }).then(response => response.data)
}

//method to create form data
const createFormData = (data) => {
       
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });
    return formData;
};