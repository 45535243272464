
import './App.css';
import About from '../Pages/about';
import Contact from '../Pages/contact';
import Highlight from '../Pages/highlight';
import Home from '../Pages/Home';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import CreateHl from '../Pages/createHl';
import LookupMaster from '../Pages/lookupMaster';
import Players from '../Pages/players';
import Clubs from '../Pages/clubs';
import Leagues from '../Pages/leagues';
import Fixtures from '../Pages/fixtures';
import {useDispatch, useSelector} from 'react-redux'
import Login from '../Pages/login';
import DashBoard from '../Pages/dashBoard';
import Unauthorized from '../Pages/unauthorized';
import CommentsAdmin from '../Pages/commentsAdmin';
import { useEffect } from 'react';
import { getApiKey } from '../slices/getToken';
//import { AxiosInterceptor } from '../Utils/axiosInstance';




function App() {
    const userState = useSelector((state) => state.auth)
    const {detail, token } = userState || {}

    const dispatch = useDispatch();

    useEffect(()=>{
        getApiKey(dispatch)
    },[dispatch])

  return (
    // <AxiosInterceptor>
        <BrowserRouter>
        <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/about' element={<About />}/>
              <Route path='/contact' element={<Contact />}/>
              <Route path='/highlight'  element={<Highlight />}/>

              <Route path='/createHl'
                element={token && detail?.is_staff ? <CreateHl /> : <Login />} 
              />
              <Route path='/lookup' 
                element={token && detail?.is_staff ? <LookupMaster /> : <Login />} 
              />
              <Route path='/players'
              element={token && detail?.is_staff ? <Players /> : <Login />}  
              />
              <Route path='/clubs'
                element={token && detail?.is_staff ? <Clubs /> : <Login />}
              />
              <Route path='/leagues' 
                element={token && detail?.is_staff ? <Leagues /> : <Login />} 
              />
              <Route path='/fixtures'
                element={token && detail?.is_staff ? <Fixtures /> : <Login />} 
              />
              <Route path='/dashboard' 
                element={token && detail?.is_staff ? <DashBoard /> : <Login />}
              />
              <Route path='/login'
                element={token && detail?.is_staff ? <DashBoard /> : <Login />}
              />
              <Route path='/comment'
                element={token && detail?.is_staff ? <CommentsAdmin /> : <Login />}
              />
              <Route path='/unauthorized' element={<Unauthorized />} />
        </Routes>
      </BrowserRouter>
   
   
  );
}

export default App;
