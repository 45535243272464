import * as yup from 'yup'

export const leaguesDetailSchema = yup.object().shape({
    clubName: yup.string().required('* Club Name required'),
    squadType: yup.object().required('* League Squad required'),
    leagueType: yup.object().required('* League Type required'),
    leagueLevel: yup.object().required('* League Level required'),
    fromDate: yup.date().required('* From date required'),
    toDate: yup.date().required('* To date required'),
    status: yup.object().required('* status required'),
});

