import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { clearUser } from "../slices/authSlice";
import '../Components/Body/dashboard.css'
import logofootball from '../Assets/images/myfootball.png'
import { Link, useNavigate } from "react-router-dom";
import person2 from '../Assets/images/person-2.png'
import getMatchComment from "../services/matchCommentService";

function DashBoard(){
    const userState = useSelector((state) => state.auth)
    const [notification, setNotification] = useState(0)
    const [caretDown, setCaretDown] = useState(false)
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const {detail, token } = userState || {}

    const handleLogout = () =>{
        dispatch(clearUser());
        localStorage.removeItem('fb-token')
        localStorage.removeItem('fb-user')
        navigate('/')
    }

    const handleNotification = async () =>{
        try{
            const criteria = {status: 'open'}
            const response = await getMatchComment(criteria);
            navigate('/comment', { 
                state: {
                    open_comments: response
                } 
            })
            
        }catch(ex){
            console.log('error in handle notification')
        }
        
    }

    const handleCaretDown = ()=>{
        setCaretDown(!caretDown)
    }

    useEffect(() =>{
        try{
            const notif = async()=>{
                const criteria = {
                    status: 'open'
                }
                const comments = await getMatchComment(criteria)
                setNotification(comments.length)
            };
            notif()
           } catch(err){
                console.log('error in getting notification page:', err)
           }    
    })
   
    return(
        <div className="landing-container">
            <div className="landing-horizontal-div">
                <div className="landing-logo">
                    <Link to="/" className='link'><img src={logofootball} alt='myFootball' className='logo'/></Link>
                </div>
                <h2>Football Highlight & Analysis</h2>
                <div className="landing-profile">
                    <div className="profile-img">
                       <div className="prof-notf">
                            <span className="notifcation-nbr" onClick={handleNotification}>{notification > 0 ? notification: ''} </span>
                            <i className="bx bx-bell"></i>
                       </div>
                        
                        <img src={person2} alt="profile-img" />
                        <span className="prof-down" onClick={handleCaretDown}><i className="bx bx-caret-down"></i></span>
                       
                    </div>
                    <div className="profile-menu">
                        <div className={`profile-setting ${caretDown ? 'active': ''}`}>
                            <Link to="/user" className='my-nav-link' ><i className="bx bx-user"><span className="prof-span">Profile</span></i></Link>
                            <li className='horizontal-divider-withoutmargin'></li>
                            <Link to="#"  className='my-nav-link'><i className="bx bx-exit" onClick={handleLogout}><span className="prof-span"></span>Logout</i></Link> 
                            <li className='horizontal-divider-withoutmargin'></li>
                            <Link to="#"  className='my-nav-link' ><i className="bx bx-cog"><span className="prof-span"></span>Setting</i></Link> 
                        </div>
                    </div>
                    
                  
                </div>
            </div>
            <div className="landing-vertical-div">
                <h2>Adjusting Factors</h2>
            {
                        token && detail?.is_staff &&
                          
                            <ul className="landing-vertical-menu">
                                    <li className='menu-item'>
                                       <span><i className="bx bx-caret-right"></i> <Link to="#" className='my-nav-link link'>Staff</Link></span> 
                                        <ul className='sub-menu-item'>
                                            <li ><Link to="/lookup" className='sub-menu-link'>Lookups</Link></li>
                                            <li ><Link to="/players" className='sub-menu-link'>Staffs</Link></li>
                            

                                        </ul>
                                    </li>
                                    <li className='menu-item'>
                                       <span><i className="bx bx-caret-right"></i> <Link to="#" className='my-nav-link link'>Football</Link></span> 
                                        <ul className='sub-menu-item'>
                                            <li ><Link to="/clubs" className='sub-menu-link'>Clubs</Link></li>
                                            <li><Link to="/leagues" className='sub-menu-link'>Leagues</Link></li>

                                        </ul>
                                    </li>
                                    <li className='menu-item'>
                                      <span><i className="bx bx-caret-right"></i> <Link to="#" className='my-nav-link link'>Match</Link></span>  
                                        <ul className='sub-menu-item'>
                                            <li ><Link to="/fixtures" className='sub-menu-link'>Fixture</Link></li>
                                            <li ><Link to="/createHl" className='sub-menu-link'>Highlight</Link></li>
                                            <li ><Link to="/comment" className='sub-menu-link'>Comment</Link></li>
                                        </ul>
                                    </li>
                                    
                            </ul>
                           
                    }
            </div>
            
        </div>
    )
}
export default DashBoard;