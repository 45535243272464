//import Cookies from 'js-cookie';
import { axiosInstance } from '../Utils/axiosInstance';


export  function login(usr){
    //const csrftoken = Cookies.get('csrftoken');  // Get the CSRF token from the cookies
    //return axiosInstance.post('/auth/login',{
    return axiosInstance.post('/login',{
        username: usr.userName,
        password: usr.passWord, 
    },
    // {
    //     withCredentials: true,
    //     headers: {
    //         'X-CSRFToken': csrftoken,  // Add the CSRF token to the headers
    //     },

    // }
    )
    .then(response => {return response.data})
    .catch(err => {
        console.log(err)
        throw err.response?.data || err.message; 
    })


}

export  function getUser(token){
    return axiosInstance.get('user',{
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
    .then(response => {return response.data})
    .catch(err => {
        console.log('error:', err)
        throw err.response?.data || err.message; 
    })
}

