import { useEffect, useState } from 'react';
import person_2 from '../../Assets/images/person-2.png' 
import './comments.css'
import getMatchComment, { addMatchComment, editMatchComment } from '../../services/matchCommentService';
import { matchCommentSchema } from '../../Validations/matchCommentValidation';
import moment from 'moment';
import { matchReplySchema } from '../../Validations/matchReply';

const createdBy = 1;
const updatedBy = 1;

function Comments({activeH}){
    // state variables
    const [masterErrors, setMasterErrors] = useState({});
    const [masterSuccess, setMasterSuccess] = useState({});
    const [commentAll, setCommentAll] = useState([])

    const [cmnt, setCmnt] = useState('');
    const [commnetBy, setCommentBy] = useState('');
    const [email, setEmail] = useState('')
    const [replyVisible, setReplyVisible] = useState(false);
    const [replyActive, setReplyActive] = useState(0);
    const [reply, setReply] = useState('');
    const [replyBy, setReplyBy] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false)


    //method to handle on change method of comment
    const handleComment = (e) =>{
        setCmnt(e.target.value)
    }

    //method to handle on change method of comment by
    const handleCommentBy = (e) =>{
        setCommentBy(e.target.value)
    }

    //method to handle on change method of email
    const handleEmail = (e) =>{
        setEmail(e.target.value);
    }

    //method to handle on change method of reply
    const handleReply = (e) =>{
        setReply(e.target.value)
    }

    //method to handle on change method of reply by
    const handleReplyBy = (e) =>{
        setReplyBy(e.target.value)
    }
    

    //method to handle the reply button click
    const handleReplyBtn = (id) =>{
       setReplyVisible(!replyVisible);
       setReplyActive(id);
       setIsSubmitted(false)
        
    }

    //method to handle the crear reply fields
    const clearReply = ()=>{
        setReply('');
        setReplyBy('');  
    }

    //method to handle the reply submit metthod
    const handleReplySubmit = async (arg, id, rply, rplyBy) =>{
        setBtnLoading(true)
        if(arg === 'submit'){
            const now = new Date();
            const atUpd = now.toISOString();
            const data = {
                reply: reply, replyBy: replyBy, id: id, updatedBy:updatedBy, updatedAt:atUpd
            }
            const isValid = await validateReply(data)
            if(isValid){ 
                try{
                    const updatedData = ({...data,
                        reply: rply + '<sep>' + reply , 
                        replyBy: rplyBy + '<sep>' + replyBy, 
                       
                    })
                    const res = await editMatchComment(id, updatedData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'matchReplyError': res.error})  
                    }
                    setIsSubmitted(false)
                    clearReply()
                    setReplyVisible(!replyVisible);  
                    setBtnLoading(false)
                }catch(err){
                    console.log('error in submit reply:', err)
                    setMasterErrors({...masterErrors, 'matchReplyError': err})
                    setBtnLoading(false)
                }
            }
        } else{
            setReplyVisible(!replyVisible); 
            setBtnLoading(false) 
        }
          
    }

   //method to validate the input feilds
   const validateForm = async (data) =>{
    try{
        await matchCommentSchema.validate(data, {abortEarly: false})
        setMasterErrors({})
        return true
    } catch(err){
        const validationErrors = {};
        err.inner?.forEach(error => {
            validationErrors[error.path] = error.message
        })
        console.log(err.message)
        setMasterErrors(validationErrors)
        return false;
    }
   }
   //method to validate the input feilds
   const validateReply = async (data) =>{
    try{
        await matchReplySchema.validate(data, {abortEarly: false})
        setMasterErrors({})
        return true
    } catch(err){
        const validationErrors = {};
        err.inner?.forEach(error => {
            validationErrors[error.path] = error.message
        })
        console.log(err.message)
        setMasterErrors(validationErrors)
        return false;
    }
   }
   
   //method to create the comment field
   const clearComment = () =>{
        setCmnt('');
        setCommentBy('');
        setEmail('');
   }

   //method to handle the comment submit method
    const handleCommentSubmit = async () =>{
        setBtnLoading(true)
        const data = ({ ...activeH, coment: cmnt, commentBy: commnetBy, email: email, status: 0})
        const isValid = await validateForm(data)
        if(isValid){
            try{
                    const now = new Date();
                    const createdAt = now.toISOString();
                    const addData = { ...data, createdAt, createdBy, updatedBy: updatedBy}
                    const res = await addMatchComment(addData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'matchCommentError': res.error})
                    }
                    setMasterSuccess({ ...masterSuccess, 'matchCommentMsg': 'Match  Comment saved successfully '})
                    clearComment()
                    setIsSubmitted(true)
                    setMasterErrors({})
                    setBtnLoading(false)
                
            } catch(err){
                setBtnLoading(false)
                if(err.response){
                    const validationErrors = {};
                    validationErrors['matchCommentError'] = err.response.data.error
                    setMasterErrors(validationErrors)
                    
                } else if(err.request){
                    console.error(err.request)
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    console.log('unkown error')
                }
            }
        } else {
            setBtnLoading(false)
            console.log('validation error: ', )
        }
        
    }

    //method to close the success message
    const closeMsg = () =>{
        setIsSubmitted(false)
    }

    //method to handle the like button
    const incrementLike = async (like, id) =>{
        setBtnLoading(true)
        const now = new Date();
        const atUpd = now.toISOString();
        const data = {
            noLike: parseInt(like) + 1,
            updatedBy: updatedBy,
            updatedAt: atUpd
        }
        try{
            const res = await editMatchComment(id, data)
            if(!res.ok){
                setMasterErrors({...masterErrors, 'matchCommentError': res.error})
            }
            setBtnLoading(false)
        }catch(err){
            console.log('error in like:', err)
            setBtnLoading(false)
        }
       
    }

    //method to handle the dis like button
    const incrementDislike = async (dislike, id) =>{
        setBtnLoading(true)
         const now = new Date();
         const atUpd = now.toISOString();
         const data = {
            noDislike: parseInt(dislike) + 1,
            updatedBy: updatedBy,
            updatedAt: atUpd
        }
        try{
            const res = await editMatchComment(id, data)
            if(!res.ok){
                setMasterErrors({...masterErrors, 'matchCommentError': res.error})
            }
            setBtnLoading(false)
        }catch(err){
            console.log('error in dislike:', err)
            setBtnLoading(false)
        }
        
    }
    
    // method to handle on page load
    useEffect(()=>{ 
        try{
            const CommentOpt = async()=>{
                const criteria = {
                    matchId: activeH?.matchId,
                    status: 'approved'
                }
                const comments = await getMatchComment(criteria)
                setCommentAll(comments)
            };
            CommentOpt()
           } catch(err){
                console.log('error in loading page:', err)
           }    
    })


    return (
        <div className="Comment">
            <p className='Comment-title'>{commentAll.length} Comments</p>
            <div className="Comments-given">
                {
                    commentAll.map((comment, index) => (
                        comment &&  <div className="comments-each" key={index}>
                                                <div className="comment-hdr">
                                                    <div className="comment-by">
                                                        <img  src={person_2} alt='person' />
                                                        <span>{comment.commentBy}</span>
                                                    </div>
                                                    <div className="comment-like-dislike">
                                                        <div className='comment-like tooltip-div'>
                                                            <p>{comment.noLike}</p>
                                                            <button className='btn' data-tooltip="Like" onClick={()=> incrementLike(comment.noLike, comment.id)} disabled={btnLoading ? true: false} style={{cursor: btnLoading ? 'not-allowed': 'pointer'}}><i className='bx bx-like' ></i></button>
                                                        </div>
                                                        <div className='comment-dislike tooltip-div'>
                                                            <p>{comment.noDislike}</p>
                                                            <button className='btn' data-tooltip="Dislike" onClick={() => incrementDislike(comment.noDislike,comment.id)} disabled={btnLoading ? true: false} style={{cursor: btnLoading ? 'not-allowed': 'pointer'}}><i className='bx bx-dislike' ></i></button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="comment-body">
                                                    <span className="comment-date">{moment(comment.createdAt).format('MMMM D, YYYY h:mm A')}</span>
                                                    <p>{comment.coment}</p> 
                                                    { comment.reply && comment.reply.split('<sep>').map((rply, i) =>(
                                                            
                                                                rply && 
                                                                    <div className='comment-reply' key={i}>
                                                                        <p className='comment-reply-content'><i className='bx bx-reply'></i>{rply}</p>
                                                                        <p className='comment-reply-by'>{comment.replyBy.split('<sep>')[i]}</p>
                                                                    </div>
                                                                
                                                            
                                                    ))}
                                                </div>

                                               {
                                                    replyVisible && replyActive === comment.id &&
                                                        <div  className="comment-reply-input">
                                                            {masterErrors['matchReplyError'] && <span className="form-error">{masterErrors['matchReplyError']}</span>}
                                                            <div className="col-input">
                                                                {masterErrors['reply'] && <span className="form-error">{masterErrors['reply']}</span>}
                                                                <textarea onChange={handleReply} name='reply'  rows={4} cols={50}  placeholder='your reply'>
                                                                </textarea>
                                                            </div>
                                                            <div className="col-input">
                                                                {masterErrors['replyBy'] && <span className="form-error">{masterErrors['replyBy']}</span>}
                                                                <input type='text' name='replyBy'  onChange={handleReplyBy} placeholder='your name' />
                                                            </div>
                                                            <div className='reply-submit-cancel'>
                                                                <button className='btn-submit' onClick={() => handleReplySubmit('submit', comment.id, comment.reply, comment.replyBy)} disabled={btnLoading ? true: false} style={{cursor: btnLoading ? 'not-allowed': 'pointer'}}>Submit</button>
                                                                <button className='btn-submit' onClick={() => handleReplySubmit('cancel', comment.id)} disabled={btnLoading ? true: false} style={{cursor: btnLoading ? 'not-allowed': 'pointer'}}>Cancel</button>
                                                            </div>
                                                            
                                                        </div>
                                               } 
                                                
                                                {
                                                    !replyVisible && 
                                                        <div className="comment-footer">
                                                            <button className='btn-reply' onClick={() => handleReplyBtn(comment.id)}>Reply</button>
                                                        </div>
                                                }
                                                

                                               <div className='horizontal-divider-black'></div>
                                            
                                            </div>
                        
                    ))
                }

            </div>
            <div >
                <div  className="Comments-to-be-given">
                   {
                    isSubmitted && <div className='success-msg'>
                                        <i className='bx bx-x btn-close' onClick={() => closeMsg()}></i>
                                        <p>Comment Submitted Succesfully</p>
                                    </div>
                   } 
                  {masterErrors['matchCommentError'] && <span className="form-error">{masterErrors['matchCommentError']}</span>}

                   <div className="col-input">
                        {masterErrors['coment'] && <span className="form-error">{masterErrors['coment']}</span>}
                        <textarea onChange={handleComment} name='coment' value={cmnt} rows={4} cols={50}  placeholder='your comment'>
                        </textarea>
                   </div>

                    
                    <div className="col-input">
                        {masterErrors['commentBy'] && <span className="form-error">{masterErrors['commentBy']}</span>}
                        <input type='text' name='commentBy' value={commnetBy} onChange={handleCommentBy} placeholder='your name' />
                    </div>
                    <div className="col-input">
                        {masterErrors['email'] && <span className="form-error">{masterErrors['email']}</span>}
                        <input type='text' name='email' value={email} onChange={handleEmail} placeholder='your email' />
                    </div>
                    <div className="col-input">
                        <button onClick={() => handleCommentSubmit()} disabled={btnLoading ? true: false} style={{cursor: btnLoading ? 'not-allowed': 'pointer'}}>Submit</button>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Comments;