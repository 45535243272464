import {axiosInstance} from "../Utils/axiosInstance";

export default function getMatchFacts(mch){
    
    return axiosInstance.get('/matchFacts', {
        params:{
            id: mch ? mch.id : null,
            matchId: mch ? mch.matchId : null,
            fixtureId: mch ? mch.fixtureId : null,
            leagueId: mch? mch.leagueId: null,
            leagueName: mch? mch.leagueName.label ? mch.leagueName.label: mch.leagueName ? mch.leagueName: null: null,
            home: mch? mch.home?.label: null,
            away: mch? mch.away?.label: null,
            stadium: mch? mch.stadium: null,
            dop: mch? mch.dop: null,
            url1: mch? mch.url1: null,
            url2: mch? mch.url2: null,
            url3: mch? mch.url3: null,
            createdAt: mch? mch.createdAt: null,
            createdBy: mch? mch.createdBy: null,
            updatedAt: mch? mch.updatedAt: null,
            updatedBy: mch? mch.updatedBy: null,
        }
        
    })
        .then(response => response.data)
}


export  function addMatchFact(mch){
    console.log('mch:', mch)
    let hmPoints = 0;
    let awPoints = 0;
    if (mch.homeGoalNo > mch.awayGoalNo){
        hmPoints = 3
    } else if (mch.awayGoalNo > mch.homeGoalNo){
        awPoints = 3
    } else if(mch.awayGoalNo === mch.homeGoalNo){
        hmPoints = 1;
        awPoints = 1;
    }
    return axiosInstance.post('/matchFacts',{
        matchId: mch?.matchId,
        homeBallPosession: mch.homeBallPosession? mch.homeBallPosession : null,
        awayBallPosession: mch.awayBallPosession? mch.awayBallPosession: null,
        homeAttempts: mch.homeAttempts? mch.homeAttempts: null,
        awayAttempts: mch.awayAttempts? mch.awayAttempts: null,

        homeCorners: mch.homeCorners? mch.homeCorners: null,
        awayCorners: mch.awayCorners? mch.awayCorners : null,
        homeKeyPass: mch.homeKeyPass? mch.homeKeyPass: null,
        awayKeyPass: mch.awayKeyPass? mch.awayKeyPass: null,
        homeOnTargets: mch.homeOnTargets? mch.homeOnTargets: null,

        homeSubstitutionAll: mch.homeSubstitutionAll? mch.homeSubstitutionAll: null,
        awaySubstitutionAll: mch.awaySubstitutionAll? mch.awaySubstitutionAll: null,
        homeLineupDict: mch.homeLineupDict? mch.homeLineupDict: null,
        awayLineupDict: mch.awayLineupDict? mch.awayLineupDict: null,
        homeGoalNo: mch?.homeGoalNo,
        awayGoalNo: mch?.awayGoalNo,
        homePoints: hmPoints,
        awayPoints: awPoints,

        awayOnTargets: mch.awayOnTargets? mch.awayOnTargets: null,
        homeLineup: mch.homeLineup? mch.homeLineup : null,
        homeLineupShort: mch.homeLineupShort? mch.homeLineupShort: null,
        awayLineup: mch.awayLineup? mch.awayLineup: null,
        awayLineupShort: mch.awayLineupShort? mch.awayLineupShort: null,

        homeGoalScorers: mch.homeGoalScorers? mch.homeGoalScorers: null,
        homeGoalScoredAt: mch.homeGoalScoredAt? mch.homeGoalScoredAt : null,
        awayGoalScorers: mch.awayGoalScorers? mch.awayGoalScorers: null,
        awayGoalScoredAt: mch.awayGoalScoredAt? mch.awayGoalScoredAt: null,
        homeGoalAssist: mch.homeGoalAssist? mch.homeGoalAssist: null,

        awayGoalAssist: mch.awayGoalAssist? mch.awayGoalAssist: null,
        homePenality: mch.homePenality? mch.homePenality : null,
        homePenalityAt: mch.homePenalityAt? mch.homePenalityAt: null,
        homePenalityStatus: mch.homePenalityStatus? mch.homePenalityStatus: null,
        awayPenality: mch.awayPenality? mch.awayPenality: null,

        awayPenalityAt: mch.awayPenalityAt? mch.awayPenalityAt: null,
        awayPenalityStatus: mch.awayPenalityStatus? mch.awayPenalityStatus : null,
        homeYellowCards: mch.homeYellowCards? mch.homeYellowCards: null,
        homeYellowCardsAt: mch.homeYellowCardsAt? mch.homeYellowCardsAt: null,
        awayYellowCards: mch.awayYellowCards? mch.awayYellowCards: null,

        awayYellowCardsAt: mch.awayYellowCardsAt? mch.awayYellowCardsAt: null,
        homeRedCards: mch.homeRedCards? mch.homeRedCards : null,
        homeRedCardsAt: mch.homeRedCardsAt? mch.homeRedCardsAt: null,
        awayRedCards: mch.awayRedCards? mch.awayRedCards: null,
        awayRedCardsAt: mch.awayRedCardsAt? mch.awayRedCardsAt: null,

        homeInjury: mch.homeInjury? mch.homeInjury: null,
        homeInjuryAt: mch.homeInjuryAt? mch.homeInjuryAt : null,
        awayInjury: mch.awayInjury? mch.awayInjury: null,
        awayInjuryAt: mch.awayInjuryAt? mch.awayInjuryAt: null,
        homeSubstitutionsIn: mch.homeSubstitutionsIn? mch.homeSubstitutionsIn: null,

        homeSubstitutionsOut: mch.homeSubstitutionsOut? mch.homeSubstitutionsOut: null,
        homeSubstitutionsAt: mch.homeSubstitutionsAt? mch.homeSubstitutionsAt : null,
        awaySubstitutionsIn: mch.awaySubstitutionsIn? mch.awaySubstitutionsIn: null,
        awaySubstitutionsOut: mch.awaySubstitutionsOut? mch.awaySubstitutionsOut: null,
        awaySubstitutionsAt: mch.awaySubstitutionsAt? mch.awaySubstitutionsAt: null,

        createdAt: mch.createdAt,
        createdBy: mch.createdBy,
        updatedBy: mch.updatedBy

    }, { withCredentials: true })
    .then(response => response.data)
}

export function editMatchFacts(id, mch){
    let hmPoints = 0;
    let awPoints = 0;
    if (mch.homeGoalNo > mch.awayGoalNo){
        hmPoints = 3
    } else if (mch.awayGoalNo > mch.homeGoalNo){
        awPoints = 3
    } else if(mch.awayGoalNo === mch.homeGoalNo){
        hmPoints = 1;
        awPoints = 1;
    }

    return axiosInstance.put('/matchFacts/' + id + '/',{
            
            matchId: mch.match?.matchId,
            homeBallPosession: mch.homeBallPosession,
            awayBallPosession: mch.awayBallPosession,
            homeAttempts: mch.homeAttempts,
            awayAttempts: mch.awayAttempts,

            homeCorners: mch.homeCorners,
            awayCorners: mch.awayCorners,
            homeKeyPass: mch.homeKeyPass,
            awayKeyPass: mch.awayKeyPass,
            homeOnTargets: mch.homeOnTargets,

            awayOnTargets: mch.awayOnTargets,
            homeLineup: mch.homeLineup,
            homeLineupShort: mch.homeLineupShort,
            awayLineup: mch.awayLineup,
            awayLineupShort: mch.awayLineupShort,

            homeGoalScorers: mch.homeGoalScorers,
            homeGoalScoredAt: mch.homeGoalScoredAt,
            awayGoalScorers: mch.awayGoalScorers,
            awayGoalScoredAt: mch.awayGoalScoredAt,
            homeGoalAssist: mch.homeGoalAssist,

            homeSubstitutionAll:  mch.homeSubstitutionAll,
            awaySubstitutionAll:  mch.awaySubstitutionAll,
            homeLineupDict: mch.homeLineupDict,
            awayLineupDict: mch.awayLineupDict,
            homeGoalNo: mch?.homeGoalNo,
            awayGoalNo: mch?.awayGoalNo,
            homePoints: hmPoints,
            awayPoints: awPoints,

            awayGoalAssist: mch.awayGoalAssist,
            homePenality: mch.homePenality,
            homePenalityAt: mch.homePenalityAt,
            homePenalityStatus: mch.homePenalityStatus,
            awayPenality: mch.awayPenality,

            awayPenalityAt: mch.awayPenalityAt,
            awayPenalityStatus: mch.awayPenalityStatus,
            homeYellowCards: mch.homeYellowCards,
            homeYellowCardsAt: mch.homeYellowCardsAt,
            awayYellowCards: mch.awayYellowCards,

            awayYellowCardsAt: mch.awayYellowCardsAt,
            homeRedCards: mch.homeRedCards,
            homeRedCardsAt: mch.homeRedCardsAt,
            awayRedCards: mch.awayRedCards,
            awayRedCardsAt: mch.awayRedCardsAt,

            homeInjury: mch.homeInjury,
            homeInjuryAt: mch.homeInjuryAt,
            awayInjury: mch.awayInjury,
            awayInjuryAt: mch.awayInjuryAt,
            homeSubstitutionsIn: mch.homeSubstitutionsIn,

            homeSubstitutionsOut: mch.homeSubstitutionsOut,
            homeSubstitutionsAt: mch.homeSubstitutionsAt,
            awaySubstitutionsIn: mch.awaySubstitutionsIn,
            awaySubstitutionsOut: mch.awaySubstitutionsOut,
            awaySubstitutionsAt: mch.awaySubstitutionsAt,

            updatedAt: mch.updatedAt,
            updatedBy: mch.updatedBy,
       }, { withCredentials: true })
    .then(response => response.data)
}

export function deleteMatchFact(id){
    return axiosInstance.delete('/matchFacts/' + id + '/',{ 
        withCredentials: true 
    }).then(response => response.data)
}
