import Header from '../Components/Header/header'
import Body from '../Components/Body/body';
import Footer from '../Components/Footer/footer';
import GoUp from '../Components/Footer/goUp';
import { useEffect, useState } from 'react';
import getMatchs from '../services/matchService';

const dopL = 10;
function Home(){
    const [matchs, setMatchs] = useState()
    
    useEffect(()=>{
        try{
            const getLatestMatch = async () =>{
                const criteria = {dopLatest: dopL}
                const hghl = await getMatchs(criteria)
                setMatchs(hghl)
            }
            getLatestMatch()

        }catch(err){
            console.log('Error in get matchs')
        }
    },[])
    return(
        <div>
            <Header matchs={matchs} setMatchs={setMatchs} />
            <Body matchs={matchs} setMatchs={setMatchs} />
            <Footer />
            <GoUp /> 
        </div>
        
    )
}
export default Home;