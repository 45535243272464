import '../Components/Body/body.css'
function Modal({isOpen, onClose, content, modalType}){
    if (!isOpen) return null;

    return(
        <div className="modal-overlay">
            <div className={modalType === 'xlg'? "modal-xlg": modalType === 'lg'? "modal-lg" : modalType === 'md'? "modal-md": modalType ==='bg'? "modal-lg-bg": "modal"}>
                <button className="close-button" onClick={onClose}><i className='bx bx-x'></i></button>
                <div className="modal-content">
                    {content}
                </div>

            </div>

        </div>
    )
}

export default Modal