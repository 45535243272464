import {axiosInstance} from "../Utils/axiosInstance"

export default function getLeagues(league){
    return axiosInstance.get('/leagues', {
        params:{
            id: league ? league.id : null,
            leagueId: league ? league.leagueId : null,
            logo: league? league.logo: null,
            name: league? league.name: null,
            country: league? league.country: null,
            doe: league? league.doe: null,
            clubsNbr: league? league.clubsNbr: null,
            createdAt: league? league.createdAt: null,
            createdBy: league? league.createdBy: null,
            updatedAt: league? league.updatedAt: null,
            updatedBy: league? league.updatedBy: null,
        }
    })
        .then(response => response.data)
}


export  function addLeague(league){
    const data ={
        leagueId: league.leagueId,
        logo: league.logo,
        name: league.name,
        country: league.country?.label,
        doe: league.doe,
        clubsNbr: league.clubsNbr,
        createdAt: league.createdAt,
        createdBy: league.createdBy,
        updatedBy: league.updatedBy
    }
    const formData = createFormData(data)
    return axiosInstance.post('/leagues',formData,
    { withCredentials: true }
    )
    .then(response => response.data)
}

export function editLeague(id, league, profile){
    let data
    if(profile){
        data = {
            logo: profile,
            name: league.name,
            country: league.country?.label,
            doe: league.doe,
            clubsNbr: league.clubsNbr,
            updatedAt: league.updatedAt,
            updatedBy: league.updatedBy,
        }
    } else{
        data = {
            name: league.name,
            country: league.country?.label,
            doe: league.doe,
            clubsNbr: league.clubsNbr,
            updatedAt: league.updatedAt,
            updatedBy: league.updatedBy,
        }
    }
    
    const formData = createFormData(data)
    return axiosInstance.put('/leagues/' + id + '/',formData,{ withCredentials: true })
    .then(response => response.data)
}

export function deleteLeague(id){
    return axiosInstance.delete('/leagues/' + id + '/',{
        withCredentials: true 
    }).then(response => response.data)
}
//method to create form data
const createFormData = (data) => {
       
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });
    return formData;
};
