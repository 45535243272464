import {axiosInstance} from "../Utils/axiosInstance"

export default function getClubs(club){
    return axiosInstance.get('/clubs', {
        params:{
            id: club ? club.id : null,
            clubId: club ? club.clubId : null,
            logo: club? club.logo: null,
            name: club? club.name: null,
            nickName: club? club.nickName: null,
            country: club? club.country: null,
            city: club? club.city: null,
            doe: club? club.doe: null,
            stadium: club? club.stadium: null,
            stadiumSeats: club? club.stadiumSeats: null,
            website: club? club.website: null,
            type: club? club.type: null,
            marketValue: club? club.marketValue: null,
            createdAt: club? club.createdAt: null,
            createdBy: club? club.createdBy: null,
            updatedAt: club? club.updatedAt: null,
            updatedBy: club? club.updatedBy: null,
        }
    })
        .then(response => response.data)
}


export  function addClub(club){
    const data = {
        clubId: club.clubId,
        logo: club.logo,
        name: club.name,
        nickName: club.nickName,
        country: club.country?.label,
        city: club.city,
        doe: club.doe,
        stadium:club.stadium,
        stadiumSeats: club.stadiumSeats,
        marketValue: club.marketValue,
        website: club.website,
        type: club.type?.value,
        createdAt: club.createdAt,
        createdBy: club.createdBy,
        updatedBy: club.updatedBy
    }
    
    const formData = createFormData(data)
    return axiosInstance.post('/clubs',formData,
    { withCredentials: true },
    )
    .then(response => response.data)
}

export function editClub(id, club, profile){
    let data
    if(profile){
        data = {
            logo: profile,
            name: club.name,
            nickName: club.nickName,
            country: club.country?.label,
            city: club.city,
            doe: club.doe,
            stadium:club.stadium,
            stadiumSeats: club.stadiumSeats,
            website: club.website,
            type: club.type?.value,
            marketValue: club.marketValue,
            updatedAt: club.updatedAt,
            updatedBy: club.updatedBy,
        }
    } else{
        data = {
            name: club.name,
            nickName: club.nickName,
            country: club.country?.label,
            city: club.city,
            doe: club.doe,
            stadium:club.stadium,
            stadiumSeats: club.stadiumSeats,
            website: club.website,
            type: club.type?.value,
            marketValue: club.marketValue,
            updatedAt: club.updatedAt,
            updatedBy: club.updatedBy,
        }
    }
    
    const formData = createFormData(data)
    return axiosInstance.put('/clubs/' + id + '/',formData,
       { withCredentials: true },
      )
    .then(response => response.data)
}

export function deleteClub(id){
    return axiosInstance.delete('/clubs/' + id + '/',
        { withCredentials: true }
    )
    .then(response => response.data)
}
//method to create form data
const createFormData = (data) => {
       
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });
    return formData;
};
