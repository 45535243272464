import axios from 'axios';
import store from '../store/store';
import { clearUser } from '../slices/authSlice';
import { useNavigate } from "react-router-dom";


// Create an Axios instance
const axiosInstance = axios.create({
   // baseURL: 'http://localhost:8000/api',
    baseURL: 'https://footballback.zeaye.com/api',
});


  //axios interceptor to attache headers
 axiosInstance.interceptors.request.use(
   async (config) => {
      //const userState = useAuthToken();
      const userState = store.getState()
     // console.log('user state:', userState)

      const authState = userState.auth;
     // console.log('Auth state:', authState); // Log the auth slice    
      //console.log('user state:', userState)
      const token = authState.token

      const apiKey = authState.apiKey
     // const {apiKey}  = getApiKey()
     // const apiKey = authState.apiKey
     // const tokenPublic = authState.publicToken

     
     // console.log(apiKey, tokenPublic)

      /* if (tokenPublic && config.method === 'get') {
        config.headers['Authorization'] = `Bearer ${tokenPublic}`;
      } */

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } 

      if(apiKey){
        config.headers['X-API-KEY'] = `${apiKey}`;
      }
     
       // Dynamically set Content-Type based on the request
      if (config.data instanceof FormData) {
          config.headers['Content-Type'] = 'multipart/form-data';
      } else if (!config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json';
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );


  // Axios interceptor to handle http errors
  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
       // const { status, data } = error.response;
        const status = error.response?.status;
        const data = error.response?.data;
        const message = data ? data.detail : null;
        const originalRequest = error.config;
        const navigate = useNavigate();
        

        if (status === 401 && !originalRequest._retry) {
          // Check if the error is due to token expiration
          if (error.config.url.includes('/login/refresh/')) {
            // If refresh token request failed, clear tokens and redirect
            store.dispatch(clearUser());
            if (typeof window !== 'undefined') {
              navigate('/unauthorized');
            }
            return Promise.reject(error);
          } else {
            const userState = store.getState()
            const tokenRefresh = userState.tokenRefresh
            if(tokenRefresh){
              originalRequest._retry = true;
              try{
                const response = await axiosInstance.post('login/refresh', {
                    refresh: tokenRefresh
                });
                localStorage.setItem('fb-token', response.access)
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.access}`;
                return axiosInstance(originalRequest);

              } catch(err){
                console.error('Token refresh failed', err);
                store.dispatch(clearUser());
                if (typeof window !== 'undefined') {
                  navigate('/unauthorized');
                }
                return Promise.reject(err);
              }
            } else if(!message.includes('No active account found')){
              store.dispatch(clearUser());
              if (typeof window !== 'undefined') {
                navigate('/unauthorized');
              }
              return Promise.reject(error);
            }
            
          }
           
        } else if (error.response && error.response.status === 403) {
          // Redirect to an Unauthorized page or show a message
          navigate('/unauthorized');
        } // Handle 400 Bad Request (e.g., invalid credentials during login)
        else if (error.response && error.response.status === 400 && error.response.data && error.response.data.detail) {
          // Do not redirect; handle this on the login page
          return Promise.reject({
            ...error,
            response: {
              ...error.response,
              status: 400,
              statusText: 'Invalid login credentials',
            },
          });
        }
        return Promise.reject(error);
    }
  );




export { axiosInstance } ;
