import * as yup from 'yup'


export const masterSchema = yup.object().shape({
    masterId: yup.number().typeError('* Master ID must be a number').required('* Master Id required'),
    value: yup.string().min(3, '* Description should be at least 3 characters').required('* Description required')
});

export const detailSchema = yup.object().shape({
    detailId: yup.number().typeError('* Detail ID must be a number').required('* Detail Id required'),
    value: yup.string().min(2, '* Description should be at least 3 characters').required('* Description required')
});