import React, { useState } from 'react'
import "./header.css"
import "../../Assets/boxicons.min.css"
import { Link } from "react-router-dom"

import logofootball from '../../Assets/images/myfootball.png' 
import Menu from './menu'
import Search from './search'
import Upcoming from './upcoming'
import {useSelector} from 'react-redux'
/*import logofootballWhite from '../Assets/images/myfootball-white.png' */

function Header({matchs, setMatchs}){
    const userState = useSelector((state) => state.auth)
    const {detail, token } = userState || {}

    const [smallMob, setSmallMob] = useState(false)

    const handleSmallMob = () =>{
        setSmallMob(!smallMob)
    }
    return (
        <div className="my-container">
            <div className="header-common">
                    <div className="header-common-left">
                        <Link to="/" className='link'><img src={logofootball} alt='myFootball' className='logo'/></Link>
                    </div>
                    <div className="header-common-right">
                        <div className="header-common-all">
                            <div className="header-common-settings">
                                <ul className={`header-settings-sub-menu ${smallMob ? 'active': ''}`}>
                                        <li> <Link to="/about" className='link'>About</Link></li>
                                        <li> <Link to="/contact" className='link'>contact</Link></li>
                                    {
                                        token && detail?.is_staff? 
                                        <li> <Link to="/dashboard" className='link'>dashboard</Link></li> 
                                        :
                                        <li> <Link to="/login" className='link'>Login</Link></li> 
                                    }
                                        
                                </ul>

                            
                            </div>
                            <div className="header-common-social">
                                <ul className='header-social-sub-menu'>
                                    <li><Link to="/" className='link'><i className='bx bxl-facebook'></i></Link></li>
                                    <li><Link to="/" className='link'><i className='bx bxl-twitter'></i></Link></li>
                                    <li><Link to="/" className='link'><i className='bx bxl-instagram'></i></Link></li>
                                </ul>
                                
                            </div>

                        </div>
                        <div className="hamburger" onClick={handleSmallMob}>
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                    </div>
                    
                    <div className='horizontal-divider-withoutmargin'>

                    </div>       
            </div>
            
            <Search matchs={matchs} setMatchs={setMatchs} />
            
            <Menu />

            <Upcoming />
            
  
        </div>
    )
}
export default Header