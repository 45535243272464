import * as yup from 'yup'

export const matchFieldSchema = yup.object().shape({
    fixtureId: yup.object().required('* match required'),
    url1: yup.string().url('Invalid url format').required('* url-1 is required'),
    url2: yup.string().url('Invalid url format'),
    url3: yup.string().url('Invalid url format')
    
});

