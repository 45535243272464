import * as yup from 'yup'

export const fixutresSchema = yup.object().shape({
    fixtureId: yup.string().typeError('* Fixture ID must be a string').required('* Fixture Id required'),
    leagueId: yup.object().required('* league Name required'),
    home: yup.object().required('* Home required'),
    away: yup.object().required('* Away required'),
    dop: yup.date().required('* date of match required'),
    country: yup.object().required('* Country required'),
});

