import React from "react";

const CustomLoadingOverlay = () =>(
    <div className="custom-loading-overlay">
        <div className="spinner">
            <span></span>
        </div>
    </div>
)

export default CustomLoadingOverlay;