import {axiosInstance} from "../Utils/axiosInstance"

export default function getMatchComment(mch){
    return axiosInstance.get('/matchComment', {
        params:{
            id: mch ? mch.id : null,
            matchId: mch ? mch.matchId : null,
            coment: mch? mch.coment: null,
            commentBy: mch? mch.commentBy: null,
            email: mch? mch.email: null,
            noLike: mch? mch.noLike: null,
            noDislike: mch? mch.noDislike: null,
            reply: mch? mch.reply: null,
            replyby: mch? mch.replyBy: null,
            status: mch? mch.status?.label? mch.status.label : mch.status? mch.status : null : null,
            leagueName: mch? mch.leagueName?.label? mch.leagueName.label : mch.leagueName? mch.leagueName : null : null,
            home: mch? mch.home?.label? mch.home.label : mch.home? mch.home : null : null,
            away: mch? mch.away?.label? mch.away.label : mch.away? mch.away : null : null,
            country: mch? mch.country?.label? mch.country.label : mch.country? mch.country : null : null,
            stadium: mch? mch.stadium: null,
            dop: mch? mch.dop: null,
            createdAt: mch? mch.createdAt: null,
            createdBy: mch? mch.createdBy: null,
            updatedAt: mch? mch.updatedAt: null,
            updatedBy: mch? mch.updatedBy: null,
        }
        
    })
        .then(response => response.data)
}


export  function addMatchComment(mch){
    return axiosInstance.post('/matchComment',{
        matchId: mch?.matchId,
        coment: mch.coment? mch.coment : null,
        commentBy: mch.commentBy? mch.commentBy: null,
        email: mch.email? mch.email: null,
        status: mch.status? mch.status: 0,
        createdAt: mch.createdAt,
        createdBy: mch.createdBy,
        updatedBy: mch.updatedBy
       
    },
    {withCredentials: true}
    )
    .then(response => response.data)
}

export function editMatchComment(id, mch){
    return axiosInstance.put('/matchComment/' + id + '/',{
            coment: mch?.coment,
            commentBy: mch?.commentBy,
            email: mch?.email,
            noLike: mch?.noLike,
            noDislike: mch?.noDislike,
            replyBy: mch?.replyBy,
            reply: mch?.reply,
            status: mch?.status?.value ,
            updatedAt: mch.updatedAt,
            updatedBy: mch.updatedBy,
       },
       {withCredentials: true}
    )
    .then(response => response.data)
    
}

export function deleteMatchComment(id){
    return axiosInstance.delete('/matchComment/' + id + '/',
    { withCredentials: true }
    )
    .then(response => response.data)
}

