import { AgGridReact } from "ag-grid-react";
import React, {  forwardRef, useImperativeHandle, useState } from "react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css'
import ImageRenderer, { TextRenderer } from "../Components/Body/imageRenderer";
import ShowDetail from "./showDetail";
import getPlayers from "../services/playersService";
import getClubs from "../services/clubsService";

const Filter = forwardRef((props, ref)=>{

    const {showFilterPopup, onHideFilterPopup, setStaffId, fullName, setStaffFullName, tableName} = props
    
    const [filteredData, setFilteredData] = useState([])
  //  const [selectedRows, setSelectedRows] = useState([])
    
    //method to format the text and number value displayed in grid
   const textValueFormatter = (params) =>{
    if(params.value === null){
        return ''
    }
    return params.value
}

    let cols = ''
    if(tableName === 'clubs'){
        cols = [
            {headerName:'Id', field: 'id', hide: true },
            {headerName:'club Id', field: 'clubId', width:120, cellRenderer: TextRenderer, cellRendererParams:{showDetail: ShowDetail}},
            {headerName:'logo', field: 'logo', width:120, cellRenderer: ImageRenderer, cellRendererParams:{showDetail: ShowDetail}},
            {headerName:'Name', field: 'name', width: 320, valueFormatter: textValueFormatter},
            {headerName:'Country', field: 'country', width:120, valueFormatter: textValueFormatter},
    
        ] 
    } else{
        cols =[
            {headerName:'Id', field: 'id', hide: true },
            {headerName:'staff Id', field: 'playerId', width:120, cellRenderer: TextRenderer, cellRendererParams:{showDetail: ShowDetail}},
            {headerName:'profile', field: 'playerPhoto', width:120, cellRenderer: ImageRenderer, cellRendererParams:{showDetail: ShowDetail}},
            {headerName:'Full Name', field: 'fullName', width: 320, valueFormatter: textValueFormatter},
            {headerName:'Country', field: 'citizenShip', width:120, valueFormatter: textValueFormatter},
    
        ]
    }

    const [colDef] = useState(cols)
   

     //method to handle selected rows of ag-grid change
     const onSelectionChanged = (e) =>{
        const rows = e.api.getSelectedRows();
       // setSelectedRows(rows) 
       if(tableName === 'clubs'){
            setStaffFullName(rows[0]?.name)
            setStaffId(rows[0]?.id)
       } else{
            setStaffFullName(rows[0]?.fullName)
            setStaffId(rows[0]?.id)
       }
        
    }
    //method to handle selected rows of ag-grid change
    const onRowClicked = (e) =>{
        const row = e.data;
       // setSelectedRows(row) 
        if(tableName === 'clubs'){
            setStaffFullName(row.name)
            setStaffId(row.id)
        } else{
            setStaffFullName(row.fullName)
            setStaffId(row.id)
        }
        
        
    }
  
   

    const rowClassRules = {
       // 'selected-row': (params) => selectedRows?.includes(params.data.id),
        'ag-row-even': (params) => params.node.rowIndex % 2 === 0,
        'ag-row-odd': (params) => params.node.rowIndex % 2 !== 0,
    }
     //defaut ag-grid column attributes
     const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
       
    }
    const handleFullNameChange = (e) =>{
        setStaffFullName(e.target.value)
    }

   //method to get staff selection options
   useImperativeHandle(ref, ()=>({
     handleFilter,
   }))
   const handleFilter = async (e)=>{
    try{
        let response
        if(tableName === 'clubs'){
            const data = ({
                name: fullName,
            })
            response = await getClubs(data);
        } else{
            const data = ({
                fullName: fullName,
                status: 'Active'
            })
            response = await getPlayers(data);
        }
            
            setFilteredData(response)
        
    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
    
}


    

    return(
        <div className={`popup ${showFilterPopup ? 'show': ''}`}>
             <div className="popup-content">
                <div className="popup-hdr">
                    <p>Filter</p>
                    <button className="close-button" onClick={onHideFilterPopup}><i className='bx bx-x'></i></button>
                </div>
               
                <div className="filter-body">
                    <div className="filter-hdr">
                        <input type="text" name="fullName" value={fullName} onChange={handleFullNameChange} placeholder="full name " />
                        <button onClick={handleFilter} className="filter-btn"><i className="bx bx-search"></i></button>
                    </div>

                    <div className="ag-theme-quartz ag-grid-cont">
                        <AgGridReact
                            columnDefs={colDef}
                            rowData={filteredData} 
                            rowSelection="single" 
                            onSelectionChanged={onSelectionChanged}
                            defaultColDef={defaultColDef}
                            rowClassRules={rowClassRules}
                            onRowClicked={onRowClicked}
                            onRowSelected={onSelectionChanged}
                        />

                        
                    </div>
                    
                </div>
                <div className="popup-buttons">
                    <button onClick={onHideFilterPopup} className="popup-no">Ok</button>
                </div>
             </div>
           
        </div>
    )


}) 
    

export default Filter;