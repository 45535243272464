import * as yup from 'yup'
const FILE_SIZE = 1024 * 1024 * 2
const SUPPORTED_FORMATS = ['image/jpg','image/jpeg', 'image/gif', 'image/png']


export const playersSchema = yup.object().shape({
    playerId: yup.string().typeError('* Player ID must be a string').required('* Player Id required'),
    profile: yup.mixed()
        .nullable()
        .test('fileSize', 'Upload an image size < 2MB', value => {return !value ||(value && value.size <= FILE_SIZE)})
        .test('fileFormat', 'Unsupported Format, only jpg, jpeg, gif, png are supported', value => {return !value || (value && SUPPORTED_FORMATS.includes(value.type))}),
    firstName: yup.string().required('* First Name required'),
    lastName: yup.string().required('* Last Name required'),
    fullName: yup.string().required('* Full Name required'),
    dob: yup.date().required('* Date of Birth required'),
    citizenShip: yup.object().required('* CitizenShip required'),
    currentNational: yup.object().required('* Nationality required'),
    height: yup.number().typeError('Height must be a number').positive('Height must be positive').required('* Photo required'),
    position: yup.object().required('* Position required'),
    foot: yup.object().required('* Foot required'),
    status: yup.object().required('* Status required')
});

