import React, { useEffect, useRef, useState } from "react";
import CustomLoadingOverlay from "../Components/Body/loadingOverlay";
import getMatchComment, { addMatchComment, deleteMatchComment, editMatchComment } from "../services/matchCommentService";
import { matchCommentSchema } from "../Validations/matchCommentValidation";
import { getlookupDetail } from "../services/lookupService";
import getLeagues from "../services/leaguesService";
import getClubs from "../services/clubsService";
import ConfirmationPopup from "../Utils/confirmationPopup";
import Menu from "../Components/Header/menu";
import Search from "../Components/Header/search";
import { Link, useLocation } from "react-router-dom";
import Actions from "../Components/Header/actions";
import SearchMod from "../Components/Body/searchMod";
import { AgGridReact } from "ag-grid-react";
import AddMod from "../Components/Body/addMod";

const createdBy = 1;
const updatedBy = 1;

function CommentsAdmin (){
    // state variables
    const [rowData, setRowData] = useState([])
    const [masterErrors, setMasterErrors] = useState({});
    const [showSearch, setShowSearch] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [moduleTitle, setModuleTitle] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [loading, setLoading] = useState(false);
    const [nationalityOptions, setNationalityOptions] = useState()
    const [statusOptions, setStatusOptions] = useState()
    const [isloading, setIsLoading] = useState(true)
    const [leagueOptions, setLeagueOptions] = useState()
    const [clubsOptions, setClubsOptions] = useState()


    const location = useLocation()
    //const {allMatchs, activeH} = location.state || {}
    const open_comments = location.state.open_comments || null;

    

    

    //constant variables
    const gridOptions = {loadingOverlayComponent: CustomLoadingOverlay}
    const gridApiRef = useRef(null)
    const loadingTimoutRef = useRef(null);

    //method to format the date displayed in grid
    const dateFormatter = params =>{
        if(!params.value){
            return '';
        }
        const date = new Date(params.value)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
   }


     

   //method to format the select value
   const selectValueFormatter = (params) =>{
       if (!params.value)
       {
           return ''
       }
       return params.value.label
   }

   
   
   
   //method to format the text and number value displayed in grid
   const textValueFormatter = (params) =>{
       if(params.value === null){
           return ''
       }
       return params.value
   }

   
   
    
    const [columnDefs] = useState([
        {headerName:'Comment Id', field: 'id'},
        {headerName:'League Name', field: 'leagueName', valueFormatter: textValueFormatter},
        {headerName:'Home', field: 'home', valueFormatter: selectValueFormatter},
        {headerName:'Away', field: 'away', valueFormatter: selectValueFormatter},
        {headerName:'Country', field: 'country', valueFormatter: selectValueFormatter},
        {headerName:'Stadium', field: 'stadium', valueFormatter: textValueFormatter},
        {headerName:'Date of play', field: 'dop',  valueFormatter:dateFormatter},
        {headerName:'Comment', field: 'coment', valueFormatter: textValueFormatter},
        {headerName:'Commented By', field: 'commentBy', valueFormatter: textValueFormatter},
        {headerName:'Email', field: 'email', valueFormatter: textValueFormatter},
        {headerName:'Like', field: 'noLike', valueFormatter: textValueFormatter},
        {headerName:'Dislike', field: 'noDislike', valueFormatter: textValueFormatter},
        {headerName:'Reply', field: 'replyBy', valueFormatter: textValueFormatter},
        {headerName:'Replied By', field: 'replyBy', valueFormatter: textValueFormatter},
        {headerName:'Status', field: 'status', valueFormatter: selectValueFormatter},
        {headerName: 'Created At', field:'createdAt', valueFormatter: dateFormatter},
        {headerName: 'Created By', field:'createdBy', valueFormatter: textValueFormatter},
        {headerName: 'Updated At', field:'updatedAt', valueFormatter: dateFormatter},
        {headerName: 'Updated By', field:'updatedBy', valueFormatter: textValueFormatter},
    ]);
    const [masterData, setMasterData] = useState({
       id:'',
       leagueName:'',
       home:'',
       away:'',
       country: '',
       stadium:'',
       dop:'',
       coment: '',
       noLike:'',
       noDislike:'',
       commentBy: '',
       reply:'',
       replyBy:'',
       createdAt:''
    })
    
    const [masterDataSearch, setMasterDataSearch] = useState({
        id:'',
        leagueName:'',
        home:'',
        away:'',
        country: '',
        stadium:'',
        dop:'',
        coment: '',
        noLike:'',
        noDislike:'',
        commentBy: '',
        reply:'',
        replyBy:'',
        createdAt:''
    })

    const rowClassRules = {
        'selected-row': (params) => selectedRows.includes(params.data.id),
        'ag-row-even': (params) => params.node.rowIndex % 2 === 0,
        'ag-row-odd': (params) => params.node.rowIndex % 2 !== 0,
    }
    
    
    //use-effect method to persist the selection state and reapply it
    useEffect(() =>{
        if(gridApiRef.current.api){
            gridApiRef.current.api.forEachNode(node =>{
                node.setSelected(selectedRows.includes(node.data))
            })
        }
    }
    ,[rowData, selectedRows])
    
  

    //methods to remove time out 
    const removeTimeout = () =>{
        if(loadingTimoutRef.current){
            clearTimeout(loadingTimoutRef.current)
        } 
    }

    //method to be executed on ag-grid ready, resize the ag-grid column as per screen width
    const onGridReady = (params) =>{
        gridApiRef.current = params;
       // params.api.sizeColumnsToFit();
        
        params.api.forEachNode(node =>{
            node.setSelected(selectedRows.includes(node.data))
        })
         
    }

    //defaut ag-grid column attributes
    const defaultColDef = {
        resizable: true,
        sortable: true,
       // flex: 1,
        filter: true,
        width:220
       
    }

    
    //method to handle selected rows of ag-grid change
    const onSelectionChanged = (e) =>{
        const selectedRows = e.api.getSelectedRows();
        setSelectedRows(selectedRows)
        setMasterData(selectedRows[0])             
    }
   
    console.log('open comments:', open_comments)
   // method to handle refresh 
   const refresh = async (e) =>{
    try{
        setLoading(true);
        loadingTimoutRef.current = setTimeout(() => {
            setLoading(false);    
        }, 60000);

       const response = await getMatchComment();
       
      
       const updatedResponse = response.map(rs =>{
           const nation = nationalityOptions?.find(opt => opt.label === rs.country)
           const leag = leagueOptions?.find(opt => opt.label === rs.leagueName)
           const home = clubsOptions?.find(opt => opt.label === rs.home)
           const away = clubsOptions?.find(opt => opt.label === rs.away)
           const status = statusOptions?.find(opt => opt.label === rs.status)

           const date = new Date(rs.dop);
           const formattedDate = date.toISOString().slice(0, 16);
           

           return {
               ...rs,
               country: nation,
               leagueId: leag,
               home: home,
               away: away,
               status: status,
               dop: formattedDate
           }
       })
       
    
        setRowData(updatedResponse)
        setLoading(false)
        removeTimeout()

    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
   }

   
   


   //method to excute when search button clicked
   const showSearchDiv = () => {
        if(showSearch){
            setShowSearch(false)
        } else{
            setShowSearch(true)
        }
   }

   


   
    //methos to be execute when edit button clicks
    const showEditDiv = () =>{
        setModuleTitle('Edit a record')
        setIsEdit(true)
        if(showAdd){
            setShowAdd(false)
        } else{
            setShowAdd(true)
            setMasterData(selectedRows[0]) 
        }
        
    }

    //method to be executed when delete button clicks
    const showDeleteDiv = (e) =>{
        setShowPopup(true);
    }
    

    //method to be executed when cancel button clicks
    const handleCancel = () =>{
        setShowPopup(false)
    }


    //method to be executed when cancel button of search module  clicks
   const cancelSearch = () =>{
        setShowSearch(false)
   }

 

  

   //handle master search data change
   const handleMasterSeachChange = (e)=>{
       const {name, value} = e.target;
       setMasterDataSearch({ ...masterDataSearch, [name]: value})
   }

   
   //handle master search Select data change
   const handleMasterSeachSelectChange = (selectedOption, actionMeta)=>{
       const {name} = actionMeta;
       setMasterDataSearch({ ...masterDataSearch, [name]: selectedOption})
   }

    //handle master data change
   const handleMasterChange = (e) =>{
        const {name, value} = e.target;
        setMasterData({ ...masterData, [name]: value})
   }
    //handle master data Select change
    const handleMasterSelectChange = (selectedOption, actionMeta) =>{
       const {name } = actionMeta;
       
       setMasterData({ ...masterData, [name]: selectedOption})
       
     /*   if(name === 'leagueId'){
           setSelectedLeague(selectedOption)
           HandleclubsOption()
       } */
  }


 /*  // method to set clubs option
  const HandleclubsOption =  async () =>{
        const clb = await getSelectArgOptions('club');
        setClubsOptions(clb)
  } */

  

   // handle seach button actions
   const submitSearch = async () =>{
    try{
        setLoading(true);
        loadingTimoutRef.current = setTimeout(() => {
            setLoading(false);
        }, 60000);

        const response = await getMatchComment(masterDataSearch);
        if(!response.ok){
           setMasterErrors({...masterErrors, 'error': response.error})
       }

       const updatedResponse = response.map(rs =>{
           const nation = nationalityOptions?.find(opt => opt.label === rs.country)
           const leag = leagueOptions?.find(opt => opt.label === rs.leagueName)
           const home = clubsOptions?.find(opt => opt.label === rs.home)
           const away = clubsOptions?.find(opt => opt.label === rs.away)
           const status = statusOptions?.find(opt => opt.label === rs.status)

           const date = new Date(rs.dop);
           const formattedDate = date.toISOString().slice(0, 16);
           

           return {
               ...rs,
               country: nation,
               leagueId: leag,
               home: home,
               away: away,
               status: status,
               dop: formattedDate
           }
       })
       
       
        setRowData(updatedResponse)
        setShowSearch(false)
        setLoading(false);
        removeTimeout(); 

    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
   }
    
   //method to crear the seach module fields
   const clearSearch = () =>{
       setMasterDataSearch({
        id:'',
        leagueName:'',
        home:'',
        away:'',
        country: '',
        stadium:'',
        dop:'',
        coment: '',
        commentBy: '',
        reply:'',
        replyBy:'',
        createdAt:''
       })
        
   }

   

  
   //method to validate the input feilds
   const validateForm = async () =>{
    try{
        await matchCommentSchema.validate(masterData, {abortEarly: false})
        setMasterErrors({})
        return true
    } catch(err){
        const validationErrors = {};
        err.inner?.forEach(error => {
            validationErrors[error.path] = error.message
        })
        console.log(validationErrors)
        setMasterErrors(validationErrors)
        return false;
    }
   }

   //method to handle the delete button actions
   const handleDelete = async () =>{
    try{
            for(const rw of selectedRows){
                const id = rw.id;
                const res =await deleteMatchComment(id)
                if(!res.ok){
                   setMasterErrors({...masterErrors, 'error': res.error})
               }
            }     
            setShowPopup(false)
            refresh()
        } catch(err){
            if(err.response){
                const validationErrors = {};
                validationErrors['error'] = err.response.data.error
                setMasterErrors(validationErrors)
                
            } else if(err.request){
                console.error(err.request)
                console.log('no respone from server')
            } else{
                console.error('error', err.message)
                console.log('unkown error')
            }
        }
   }
  
   //method to handle save button actions
   const submitAdd = async (e) =>{
        e.preventDefault();
       
        
        const isValid = await validateForm()
        if(isValid){
            try{
                if(isEdit){
                   const now = new Date();
                   const updatedAt = now.toISOString();
                   const addData = { ...masterData, updatedBy, updatedAt }
                   const res = await editMatchComment(addData.id, addData)
                    if(!res.ok){
                       setMasterErrors({...masterErrors, 'error': res.error})
                   }
                    setShowAdd(false)
                    refresh()
                } else{
                   const now = new Date();
                   const createdAt = now.toISOString();
                   const updatedAt = now.toISOString();
                   const addData = { ...masterData, createdAt, createdBy, updatedAt, updatedBy}
                   const res = await addMatchComment(addData)
                   if(!res.ok){
                       setMasterErrors({...masterErrors, 'error': res.error})
                   }
                    setShowAdd(false)
                    refresh()
                }
                
            } catch(err){
                if(err.response){
                    const validationErrors = {};
                    validationErrors['error'] = err.response.data.error
                    setMasterErrors(validationErrors)
                    
                } else if(err.request){
                    console.error(err.request)
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    console.log('unkown error')
                }
            }
        } else {
           console.log('validation error: ', )
        }
   }

   


   //method to handle export button actions
   const handleExport = () =>{
        const params = {
            fileName: 'fixtures_export.csv',
            columnSeparator: ','
        }
        gridApiRef.current.api.exportDataAsCsv(params)
   }

   const getSelectOptions = async (masterId) => {
       try{
               const response = await getlookupDetail(masterId);
          
               const formatedOptions = response.map(opt => ({
                   value: opt.detailId,
                   label: opt.value
               }))
               return formatedOptions;
       } catch(err){
           console.log('Error fetching rowData: ', err)
       }
   };

  
   useEffect(()=>{
      try{
       const footOpt = async()=>{
           const nopt = await getSelectOptions(4);
           const statusOpt = await getSelectOptions(12);

           const response = await getLeagues();
                    
           const leag = response.map(opt => ({
               value: opt.id,
               label: opt.name
           }))

           const response1 = await getClubs();
                   
           const clb = response1.map(opt => ({
               value: opt.id,
               label: opt.name
           }))
         
           setNationalityOptions(nopt);
           setLeagueOptions(leag)
           setClubsOptions(clb)              
           setStatusOptions(statusOpt)
           setIsLoading(false)

           console.log('open_comments in useEffect:', open_comments)
           if(open_comments && Array.isArray(open_comments)){
                const updatedResponse = open_comments.map(rs =>{
                const nation = nopt?.find(opt => opt.label === rs.country)
                const lg = leag?.find(opt => opt.label === rs.leagueName)
                const home = clb?.find(opt => opt.label === rs.home)
                const away = clb?.find(opt => opt.label === rs.away)
                const status = statusOpt?.find(opt => opt.label === rs.status)
    
                const date = new Date(rs.dop);
                const formattedDate = date.toISOString().slice(0, 16);
                
    
                return {
                    ...rs,
                    country: nation,
                    leagueId: lg,
                    home: home,
                    away: away,
                    status: status,
                    dop: formattedDate
                }
            })
            
        
            setRowData(updatedResponse)
           }
       };
       footOpt()
      } catch(err){
       setIsLoading(true)
      } 
       
   },[open_comments])

    //method to be excuted when cancel button of add module clicks
    const cancelAdd = () =>{
        setShowAdd(false)
   }


   // search fields
   const searchFields = [
        {id: 'id', desc:'Comment ID', name: 'commentId', type:'text', value:masterDataSearch.id, onChange:handleMasterSeachChange},
        {id: 'leagueName', desc:'League Name', name: 'leagueName', type:'Select', options:leagueOptions, placeHolder:'Select league...', value:masterDataSearch.leagueName, onChange:handleMasterSeachSelectChange},
        {id: 'home', desc:'Home', name: 'home', type:'Select', options:clubsOptions, placeHolder:'Select home...', value:masterDataSearch.home, onChange:handleMasterSeachSelectChange},
        {id: 'away', desc:'Away', name: 'away', type:'Select', options:clubsOptions, placeHolder:'Select away...', value:masterDataSearch.away, onChange:handleMasterSeachSelectChange},
        {id: 'country', desc:'Country', name: 'country', type:'Select', options:nationalityOptions, placeHolder:'Select country...', value:masterDataSearch.country, onChange:handleMasterSeachSelectChange},
        {id: 'stadium', desc:'Stadium', name: 'stadium', type:'text', value:masterDataSearch.stadium, onChange:handleMasterSeachChange},
        {id: 'dop', desc:'Match Date', name: 'dop', type:'date', value:masterDataSearch.dop, onChange:handleMasterSeachChange},
        {id: 'commentBy', desc:'Comment By', name: 'commentBy', type:'text', value:masterDataSearch.commentBy, onChange:handleMasterSeachChange},
        {id: 'replyBy', desc:'Reply By', name: 'replyBy', type:'text', value:masterDataSearch.replyBy, onChange:handleMasterSeachChange},
        {id: 'noLike', desc:'Like', name: 'noLike', type:'number', value:masterDataSearch.noLike, onChange:handleMasterSeachChange},
        {id: 'noDislike', desc:'Dis Like', name: 'noDislike', type:'number', value:masterDataSearch.noDislike, onChange:handleMasterSeachChange},
        {id: 'status', desc:'Status', name: 'status', type:'Select', options:statusOptions, placeHolder:'Select status...', value:masterDataSearch.status, onChange:handleMasterSeachSelectChange},

    ];

   

    const addFields = [
        {id: 'id', desc:'id', name: 'id', type:'number', readOnly:true, isGenerate: false,onClick:'', value:masterData? masterData.id: '', onChange:handleMasterChange},
        {id: 'coment', desc:'Comment', name: 'coment', type:'text', readOnly:true, isGenerate: false,onClick:'', value:masterData? masterData.coment: '', onChange:handleMasterChange},
        {id: 'commentBy', desc:'Comment By', name: 'commentBy', type:'text', readOnly:true, isGenerate: false,onClick:'', value:masterData? masterData.commentBy: '', onChange:handleMasterChange},
        {id: 'noLike', desc:'Like', name: 'noLike', type:'number', readOnly:true, isGenerate: false,onClick:'', value:masterData? masterData.noLike: '', onChange:handleMasterChange},
        {id: 'noDislike', desc:'Dis Like', name: 'noDislike', type:'number', readOnly:true, isGenerate: false,onClick:'', value:masterData? masterData.noDislike: '', onChange:handleMasterChange},
        {id: 'email', desc:'Email', name: 'email', type:'text', readOnly:true, isGenerate: false,onClick:'', value:masterData? masterData.email: '', onChange:handleMasterChange},
        {id: 'reply', desc:'Reply', name: 'reply', type:'text', readOnly:true, isGenerate: false,onClick:'', value:masterData? masterData.reply: '', onChange:handleMasterChange},
        {id: 'replyBy', desc:'Reply By', name: 'replyBy', type:'text', readOnly:true, isGenerate: false,onClick:'', value:masterData? masterData.replyBy: '', onChange:handleMasterChange},
        {id: 'status', desc:'Status', name: 'status', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'Select Status...', value:masterData? masterData.status:'', onChange:handleMasterSelectChange},

    ];

    return(
        <div>
            <ConfirmationPopup showPopup={showPopup} onDeleteHandler={handleDelete} onCancelHandler={handleCancel} content="Are you sure you want to delete the selected records?" />  
            <Menu />
            <Search />

                 <div>
                    <div className="current-page">
                        <p ><Link to="/dashboard" className="link">Dashboard</Link></p>
                        <p>/</p>
                        <p>Comments</p>
                    </div>
                    <div className="page-content">
                        <Actions title='Adjust Comments here' isLoading={isloading} refresh={refresh} showSearchDiv={showSearchDiv}    showEditDiv={showEditDiv} showDeleteDiv={showDeleteDiv} handleExport={handleExport} rowData={rowData} selectedRows={selectedRows} />
                        
                        <div className="page-body">

                            {
                                showSearch ?
                                <SearchMod searchFields={searchFields} submitSearch={submitSearch} cancelSearch={cancelSearch} clearSearch={clearSearch}/>
                                : ''
                            }
                            
                            <div className="ag-theme-quartz ag-grid-container" >
                                <AgGridReact 
                                    gridOptions={gridOptions}
                                    columnDefs={columnDefs}
                                    rowData={rowData} 
                                    rowSelection="multiple" 
                                    defaultColDef={defaultColDef}
                                    domLayout="autoHeight"
                                    onSelectionChanged={onSelectionChanged}
                                    pagination={true}
                                    paginationPageSize={20}
                                    paginationPageSizeSelector={[20, 40]}
                                    loadingOverlayComponentFramework={CustomLoadingOverlay}
                                    loading={loading}
                                    onGridReady={onGridReady}
                                    ref={gridApiRef}
                                    rowClassRules={rowClassRules}
                                    alwaysShowVerticalScroll={true}
                                    alwaysShowHorizontalScroll={true}
                                />
                        </div>

                            {
                                showAdd &&
                                <AddMod addFields={addFields}  submitAdd={submitAdd} moduleTitle={moduleTitle} masterErrors={masterErrors} cancelAdd={cancelAdd} />
                            }
                        
                        </div>
                        <div className="page-footer">
                        </div>

                    </div>
                </div>
            
               
        </div>
    );
}
export default CommentsAdmin